import { fieldType } from "../../redux/Types";

// export default {
//   id: { id: "id", type: fieldType.TEXT},
//   trackTitle: { id: "trackTitle", type: fieldType.TEXT},
//   session_id: { id: "session_id", type: fieldType.TEXT},
//   licenseStatus: { id: "licenseStatus", type: fieldType.TEXT },
//   alternativeTitle: { id: "alternativeTitle", type: fieldType.TEXT },
//   prodName: { id: "prodName",canCloud: true, type: fieldType.TEXT },
//   prodSubtitle: { id: "prodSubtitle", type: fieldType.TEXT },
//   catNo: { id: "catNo", type: fieldType.TEXT },
//   musicOrigin: { id: "musicOrigin", skipUserPreff: true, type: fieldType.TEXT },
//   musicOriginSubIndicator: { id: "musicOriginSubIndicator", skipUserPreff: true, type: fieldType.TEXT },
//   isrc: { id: "isrc", type: fieldType.TEXT },
//   iswc: { id: "iswc", type: fieldType.TEXT },
//   prs: { id: "prs", label: "prs", type: fieldType.TEXT, linkable: false },
//   prodArtist: { id: "prodArtist", canCloud: true, type: fieldType.TEXT, linkable: true },
//   prodTags: { id: "prodTags", canCloud: true, tag: true, type: fieldType.TEXT },
//   performer: { id: "performer", canCloud: true, type: fieldType.TEXT, linkable: true },
//   lyricist: { id: "lyricist", canCloud: true, type: fieldType.TEXT, linkable: true },
//   //bbcTrackId: { id: "bbcTrackId", type: fieldType.TEXT },
//   composerLyricist: { id: "composerLyricist", canCloud: true, type: fieldType.TEXT },
//   bbcAlbumId: { id: "bbcAlbumId", type: fieldType.TEXT },
//   albumAdminNotes: { id: "albumAdminNotes", type: fieldType.TEXT },
//   takedownType: { id: "takedownType", type: fieldType.TEXT },
//   takedownDate: { id: "takedownDate", type: fieldType.DATE },
//   searchableType: { id: "searchableType", type: fieldType.TEXT },
//   searchableFrom: { id: "searchableFrom", type: fieldType.DATE },
//   contributorsExtended: { id: "contributorsExtended", canCloud: true, type: fieldType.TEXT },
//   changeLog: { id: "changeLog", type: fieldType.TEXT },
//   pLine: { id: "pLine", type: fieldType.TEXT },
//   adaptor: { id: "adaptor", canCloud: true, type: fieldType.TEXT, linkable: true },
//   administrator: { id: "administrator", canCloud: true, type: fieldType.TEXT, linkable: true },
//   arranger: { id: "arranger", canCloud: true, type: fieldType.TEXT, linkable: true },
//   composer: { id: "composer", canCloud: true, type: fieldType.TEXT, linkable: true },
//   publisher: { id: "publisher", canCloud: true, type: fieldType.TEXT, linkable: true },
//   originalPublisher: { id: "originalPublisher", canCloud: true, type: fieldType.TEXT, linkable: true },
//   recordLabel: { id: "recordLabel", canCloud: true, type: fieldType.TEXT },
//   subLyricist: { id: "subLyricist", canCloud: true, type: fieldType.TEXT, linkable: true },
//   subAdaptor: { id: "subAdaptor", canCloud: true, type: fieldType.TEXT, linkable: true },
//   subArranger: { id: "subArranger", canCloud: true, type: fieldType.TEXT, linkable: true },
//   subPublisher: { id: "subPublisher", canCloud: true, type: fieldType.TEXT, linkable: true },
//   translator: { id: "translator", canCloud: true, type: fieldType.TEXT, linkable: true },
//   position: { id: "position", type: fieldType.TEXT },
//   duration: { id: "duration", type: fieldType.NUMBER },
//   arid: { id: "arid", type: fieldType.TEXT },
//   prodRelease: { id: "prodRelease", type: fieldType.DATE },
//   libId: { id: "libId", type: fieldType.DATE },
//   libName: { id: "libName", canCloud: true, type: fieldType.TEXT },
//   genres: { id: "genres", tag: true, canCloud: true, type: fieldType.TEXT },
//   moods: { id: "moods", tag: true, canCloud: true, type: fieldType.TEXT },
//   styles: { id: "styles", tag: true, canCloud: true, type: fieldType.TEXT },
//   keywords: { id: "keywords", tag: true, canCloud: true, type: fieldType.TEXT },
//   instruments: { id: "instruments", tag: true, canCloud: true, type: fieldType.TEXT },
//   trackNotes: { id: "trackNotes", notetag: true, type: fieldType.TEXT },
//   prodArtworkUrl: { id: "prodArtworkUrl", sys: true, type: fieldType.TEXT },
//   prodYear: { id: "prodYear", type: fieldType.NUMBER },
//   prodNumberOfDiscs: { id: "prodNumberOfDiscs", type: fieldType.NUMBER },
//   prodDiscNr: { id: "prodDiscNr", type: fieldType.NUMBER },
//   prodNotes: { id: "prodNotes", notetag: true, type: fieldType.TEXT },
//   trackVersionTitle: { id: "trackVersionTitle", type: fieldType.TEXT },
//   subIndex: { id: "subIndex", type: fieldType.TEXT },
//   bpm: { id: "bpm", tag: true, canCloud: true, type: fieldType.NUMBER },
//   tempo: { id: "tempo", tag: true, canCloud: true, type: fieldType.NUMBER },
//   player: { id: "player", sys: true, type: fieldType.TEXT },
//   selector: { id: "selector", sys: true, type: fieldType.TEXT},
//   menu: { id: "menu", sys: true, type: fieldType.TEXT },
//   wsId: { id: "wsId", sys: false, type: fieldType.TEXT },
//   wsName: { id: "wsName", sys: false, canCloud: true, type: fieldType.TEXT },
//   dateCreated: { id: "dateCreated", type: fieldType.DATE },
//   dateLastEdited: { id: "dateLastEdited", type: fieldType.DATE },
//   restricted: { id: "restricted", type: fieldType.BOOLEAN },
//   charted: { id: "charted", type: fieldType.BOOLEAN },
//   chartType: { id: "chartType", type: fieldType.TEXT },
//   chartArtist: { id: "chartArtist", type: fieldType.BOOLEAN },
//   validFrom: { id: "validFrom", type: fieldType.DATE },
//   validTo: { id: "validTo", type: fieldType.DATE },
//   preRelease: { id: "preRelease", type: fieldType.BOOLEAN },
//   preReleaseDate: { id: "preReleaseDate", type: fieldType.DATE },
//   preReleaseIndicator: { id: "preReleaseIndicator", type: fieldType.TEXT },
//   archived: { id: "archived", type: fieldType.BOOLEAN},
//   prsWorkTunecode: { id: "prsWorkTunecode", type: fieldType.TEXT},
//   prsSearchDateTime: { id: "prsSearchDateTime", type: fieldType.DATE},
//   prsWorkTitle: { id: "prsWorkTitle", type: fieldType.TEXT},
//   prsWorkWriters: { id: "prsWorkWriters", canCloud: true, type: fieldType.TEXT},
//   prsWorkPublishers: { id: "prsWorkPublishers", canCloud: true, type: fieldType.TEXT, linkable: true},
//   dhTrackId: {id: "dhTrackId", type: fieldType.TEXT},
//   dhExplicit: {id: "dhExplicit", type: fieldType.BOOLEAN},
//   territories: {id: "territories", type: fieldType.TEXT },
//   adminTags: {id: "adminTags", type: fieldType.TEXT },
//   adminNotes: {id: "adminNotes", type: fieldType.TEXT },
//   prodAdminTags: {id: "prodAdminTags", type: fieldType.TEXT },
//   mlCreated: {id: "mlCreated", skipUserPreff: true, type: fieldType.BOOLEAN },
//   cTagMcpsOwner: {id: "cTagMcpsOwner", type: fieldType.TEXT },
//   cTagNorthAmerican: {id: "cTagNorthAmerican", type: fieldType.TEXT },
//   skyTracfficLight: { id: "skyTracfficLight",type: fieldType.TEXT },
// };

export default window?._env_?.TRACK_FIELDS;