import { T_Major, T_LeafBool } from "./ModulePropTypes";
import { PT_Object } from "./ModulePermitTypes";



export const _plContentSharePlaylistAdd = {
  id: "plContentSharePlaylistAdd",
  type: T_LeafBool,
  default: false,
};

export const _plContentSharePlaylistShare = {
  id: "plContentSharePlaylistShare",
  type: T_LeafBool,
  default: false,
};

export const _plContentSharePlaylistShareFull = {
  id: "plContentSharePlaylistShareFull",
  type: T_LeafBool,
  default: false,
};

export const _plContentSharePlaylistDownload = {
  id: "plContentSharePlaylistDownload",
  type: T_LeafBool,
  default: false,
};

export const _plContentShareCanManagePlaylist = {
  id: "plContentShareCanManagePlaylist",
  type: T_LeafBool,
  default: false,
};

export const _mlPlaylistContentShare = {
  id: "mlPlaylistContentShare",
  permitType: PT_Object,
  type: T_Major,
  modules: [
    _plContentSharePlaylistAdd,
    _plContentSharePlaylistShare,
    _plContentSharePlaylistShareFull,
    _plContentSharePlaylistDownload,
    _plContentShareCanManagePlaylist,
  ],
};
