// This type is also in CentralAPI, keep consistent if you modify it
export const ContentAlertType = {
    TRACK : 1,
    ALBUM : 2
}

// This type is also in CentralAPI, keep consistent if you modify it
export const ContentAlertTypeList = [
    {id : ContentAlertType.TRACK, label: 'Track'},
    {id : ContentAlertType.ALBUM, label: 'Album'},
]

// This type is also in CentralAPI, keep consistent if you modify it
export const ContentAlertIssueType = {
    METADATA : 1,
    AUDIO : 2
}
// This type is also in CentralAPI, keep consistent if you modify it
export const ContentAlertIssueTypeList = [
    {id : ContentAlertIssueType.METADATA, label: 'Wrong Metadata'},
    {id : ContentAlertIssueType.AUDIO, label: 'Wrong Audio'},
]

// This type is also in CentralAPI, keep consistent if you modify it
export const ContentAlertStatus = {
    ALERTED : 1,
    RESOLVED : 2,
    AWAITING : 3,
}

export const ContentAlertStatusColors = {
    ALERTED : '#B22222',
    RESOLVED : '#00A300',
    AWAITING : '#ff932c'
}
// This type is also in CentralAPI, keep consistent if you modify it
export const ContentAlertStatusList = [
    {id : ContentAlertStatus.ALERTED, label: 'Alerted', color : ContentAlertStatusColors.ALERTED},
    {id : ContentAlertStatus.RESOLVED, label: 'Resolved', color : ContentAlertStatusColors.RESOLVED},
    {id : ContentAlertStatus.AWAITING, label: 'Awaiting 3rd Party', color : ContentAlertStatusColors.AWAITING},
]

export const ContentAlertPreview = {
    METADATA : 0,
    ALERT_DETAILS : 1,
}