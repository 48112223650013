// export default {
//   originall: { id: "originall", val: "all" },
//   origincommercial: { id: "origincommercial", val: "commercial" },
//   originlibrary: { id: "originlibrary", val: "library" },
//   originlibraryMcps: { id: "originlibraryMcps", val: "libraryMcps" },
//   originlibraryNonMcps: { id: "originlibraryNonMcps", val: "libraryNonMcps" },
//   origincommissioned: { id: "origincommissioned", val: "commissioned" },
//   originlive: { id: "originlive", val: "live" },
// };
export default {
  originall: { id: "originall", val: "all", label:"All" },
  prod_library_non_mechanical: { id: "prod_library_non_mechanical", val: "prod_library_non_mechanical", label:"Production Library (Non Mechanical)" },
  originlibrary: { id: "library", val: "library", label:"Prod Library" },
  satv_library: { id: "satv_library", val: "satv_library", label:"SATV (Library)" },
  spec_somm_satv_publ_only: { id: "spec_somm_satv_publ_only", val: "spec_somm_satv_publ_only", label:"Spec Comm (satv publ. only)" },
  spec_comm_satv_publ_other: { id: "spec_comm_satv_publ_other", val: "spec_comm_satv_publ_other", label:"Spec Comm (satv publ. + other)" },
  video: { id: "video", val: "video", label:"Video" },
  tv_soundtrack: { id: "tv_soundtrack", val: "tv_soundtrack", label:"TV Soundtrack" },
  film_soundtrack: { id: "film_soundtrack", val: "film_soundtrack", label:"Film Soundtrack" },
  origincommercial: { id: "commercial", val: "commercial", label:"Retail Commercial" },
  originlive: { id: "live", val: "live", label:"Live Performance" },
  origincommissioned: { id: "commissioned", val: "commissioned", label:"Spec Comm (non satv publ.)" },
  unknown: { id: "unknown", val: "unknown", label:"Unknown" },
  sound_fx: { id: "sound_fx", val: "sound_fx", label:"Sound FX" },
  actuality: { id: "actuality", val: "actuality", label:"Actuality" },
  concert_footage: { id: "concert_footage", val: "concert_footage", label:"Concert Footage" },
  film_clip: { id: "film_clip", val: "film_clip", label:"Film Clip" },
  programme_clip: { id: "programme_clip", val: "programme_clip", label:"Programme Clip" },
  studio_footage: { id: "studio_footage", val: "studio_footage", label:"Studio Footage" },
};
