import React, { useEffect } from "react";
import {
  withStyles,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  CircularProgress,
  MenuItem,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import clsx from "clsx";

export default function Confirm({
  name,
  isOpen,
  title,
  content,
  onConfirm,
  onCancel,
  confirm,
  cancel,
  classes,
  extentions,
  preStyledContent,
  innerHtmlContent,
  autoFocus
}) {
  return (
    <Dialog
      key={name}
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      class="dialogBox"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      {content && (<DialogContent>{content}</DialogContent>)}
      {preStyledContent && (<DialogContent>{preStyledContent}</DialogContent>)}
      {innerHtmlContent && (<DialogContent><span dangerouslySetInnerHTML={{__html: innerHtmlContent}}></span></DialogContent>)}
      
      <DialogActions>
        {onConfirm && confirm && (
          <Button
            class="dialogYesButton"
            onClick={onConfirm}
            color="primary"
            autoFocus={autoFocus}

            className={clsx(
              classes.globalCreateSaveButton,
              classes.clearnaceSaveButton
            )}
          >
            {confirm}
          </Button>
        )}

        {onCancel && cancel && (
          <Button
            class="dialogNoButton"
            className={clsx(
              classes.globalCloseButton
            )}
            onClick={onCancel}
            color="primary"
          >
            {cancel}
          </Button>
        )}

        { extentions && extentions.map(d=>{
          if(d.action && d.text){
              return (
                <Button
                class="dialogNoButton"
                onClick={d.action}
                color="primary"
                >
                  {d.text}
                </Button>
              )
            }
        }) }
      </DialogActions>
    </Dialog>
  );
}
