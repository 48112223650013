import React from "react";
import { BiFilterAlt } from "react-icons/bi";
import {
  FaEquals,
  FaTerminal,
  FaCheck,
  FaFont,
  FaGreaterThan,
  FaGreaterThanEqual,
  FaLessThan,
  FaLessThanEqual,
  FaNetworkWired,
} from "react-icons/fa";
import { RiCheckboxBlankCircleLine } from "react-icons/ri";
import { MdAccessTime } from "react-icons/md";
import { BsCheck } from "react-icons/bs";


export const ColumnTypes = {
  score: "score",
  player: "player",
  string: "string",
  elasticString: "elasticString",
  regularString: "regularString",
  tag: "tag",
  number: "number",
  date: "date",
  datetime: "datetime",
  options: "options",
  users: "users",
  selector: "selector",
  content_admin_selector: "content_admin_selector",
  menu: "menu",
  thumb: "thumb",
  identity: "identity",
  object: "object",
  conj: "conj",
  boolean: "boolean",
  playerAndThumb: "playerAndThumb",
  duration: "duration",
  numberAndOption: "numberAndOption",
  territories_selector: "territories_selector",
  artWorkString: "artWorkString"
};

export const PossibleColumnTypes = [
  ColumnTypes.player,
  ColumnTypes.string,
  ColumnTypes.number,
  ColumnTypes.datetime,
  ColumnTypes.date,
  ColumnTypes.selector,
  ColumnTypes.menu,
  ColumnTypes.options,
  ColumnTypes.users,
  ColumnTypes.thumb,
  ColumnTypes.identity,
];

export const FilterOperatorTypes = {
  equals: "equals",
  notEquals: "notEquals",
  contains: "contains",
  notContains: "notContains",
  startsWith: "startsWith",
  notStartsWith: "notStartsWith",
  endsWith: "endsWith",
  notEndsWith: "notEndsWith",
  exactly: "exactly",
  notExactly: "notExactly",
  greaterThan: "greaterThan",
  lessThan: "lessThan",
  greaterThanEqual: "greaterThanEqual",
  lessThanEqual: "lessThanEqual",
  range: "range",
  conjAnd: "conjAnd",
  conjOr: "conjOr",
  between: "between",
  empty: "empty",
  notEmpty: "notEmpty",
  dateTime: "dateTime",
  option: "option"
};

export const PossibleFilterOperatorTypes = [
  FilterOperatorTypes.equals,
  FilterOperatorTypes.notEquals,
  FilterOperatorTypes.contains,
  FilterOperatorTypes.notContains,
  FilterOperatorTypes.startsWith,
  FilterOperatorTypes.notStartsWith,
  FilterOperatorTypes.endsWith,
  FilterOperatorTypes.notEndsWith,
  FilterOperatorTypes.exactly,
  FilterOperatorTypes.notExactly,
  FilterOperatorTypes.greaterThan,
  FilterOperatorTypes.lessThan,
  FilterOperatorTypes.greaterThanEqual,
  FilterOperatorTypes.lessThanEqual,
];

export const ColumnStickyTypes = {
  left: "left",
  right: "right",
};
export const optionSubTypes = {
  optionWithColor: "optionWithColor",
  optionWithIcon: "optionWithIcon",
};

export const PossibleColumnStickyTypes = [
  ColumnStickyTypes.left,
  ColumnStickyTypes.right,
];

export const JustificationTypes = {
  left: "left",
  center: "center",
  right: "right",
};

export const PossibleJustificationTypes = [
  JustificationTypes.left,
  JustificationTypes.center,
  JustificationTypes.right,
];

export const SortTypes = {
  asc: "asc",
  desc: "desc",
};

export const operatorTypeTagCloud = [
  {id:'+', label:' AND'},
  {id:'~', label:' OR'},
  {id:'-', label:' NOT'},
];

export const PossibleSortTypes = [SortTypes.asc, SortTypes.desc];

export const FilterInputTypes = {
  none: "none",
  text: "text",
  number: "number",
  options: "options",
  date: "date",
  time: "time",
  dateTime: "dateTime",
  dateRange: "dateRange",
};

const activeStyle = {
  color: "#E0A607",
};

export const TerritoryList = [
  {id: "GB", label: "GB", default:true},
  {id: "US", label: "US"},
  {id: "AD", label: "AD"},
{id: "AE", label: "AE"},
{id: "AF", label: "AF"},
{id: "AG", label: "AG"},
{id: "AL", label: "AL"},
{id: "AM", label: "AM"},
{id: "AO", label: "AO"},
{id: "AR", label: "AR"},
{id: "AT", label: "AT"},
{id: "AU", label: "AU"},
{id: "AZ", label: "AZ"},
{id: "BA", label: "BA"},
{id: "BB", label: "BB"},
{id: "BD", label: "BD"},
{id: "BE", label: "BE"},
{id: "BF", label: "BF"},
{id: "BG", label: "BG"},
{id: "BH", label: "BH"},
{id: "BI", label: "BI"},
{id: "BJ", label: "BJ"},
{id: "BN", label: "BN"},
{id: "BO", label: "BO"},
{id: "BR", label: "BR"},
{id: "BS", label: "BS"},
{id: "BT", label: "BT"},
{id: "BW", label: "BW"},
{id: "BY", label: "BY"},
{id: "BZ", label: "BZ"},
{id: "CA", label: "CA"},
{id: "CD", label: "CD"},
{id: "CF", label: "CF"},
{id: "CG", label: "CG"},
{id: "CH", label: "CH"},
{id: "CI", label: "CI"},
{id: "CL", label: "CL"},
{id: "CM", label: "CM"},
{id: "CN", label: "CN"},
{id: "CO", label: "CO"},
{id: "CR", label: "CR"},
{id: "CU", label: "CU"},
{id: "CV", label: "CV"},
{id: "CY", label: "CY"},
{id: "CZ", label: "CZ"},
{id: "DE", label: "DE"},
{id: "DJ", label: "DJ"},
{id: "DK", label: "DK"},
{id: "DM", label: "DM"},
{id: "DO", label: "DO"},
{id: "DZ", label: "DZ"},
{id: "EC", label: "EC"},
{id: "EE", label: "EE"},
{id: "EG", label: "EG"},
{id: "EH", label: "EH"},
{id: "ER", label: "ER"},
{id: "ES", label: "ES"},
{id: "ET", label: "ET"},
{id: "FI", label: "FI"},
{id: "FJ", label: "FJ"},
{id: "FM", label: "FM"},
{id: "FR", label: "FR"},
{id: "GA", label: "GA"},
{id: "GD", label: "GD"},
{id: "GE", label: "GE"},
{id: "GH", label: "GH"},
{id: "GM", label: "GM"},
{id: "GN", label: "GN"},
{id: "GQ", label: "GQ"},
{id: "GR", label: "GR"},
{id: "GT", label: "GT"},
{id: "GW", label: "GW"},
{id: "GY", label: "GY"},
{id: "HK", label: "HK"},
{id: "HN", label: "HN"},
{id: "HR", label: "HR"},
{id: "HT", label: "HT"},
{id: "HU", label: "HU"},
{id: "ID", label: "ID"},
{id: "IE", label: "IE"},
{id: "IL", label: "IL"},
{id: "IN", label: "IN"},
{id: "IQ", label: "IQ"},
{id: "IR", label: "IR"},
{id: "IS", label: "IS"},
{id: "IT", label: "IT"},
{id: "JM", label: "JM"},
{id: "JO", label: "JO"},
{id: "JP", label: "JP"},
{id: "KE", label: "KE"},
{id: "KG", label: "KG"},
{id: "KH", label: "KH"},
{id: "KI", label: "KI"},
{id: "KM", label: "KM"},
{id: "KN", label: "KN"},
{id: "KP", label: "KP"},
{id: "KR", label: "KR"},
{id: "KW", label: "KW"},
{id: "KZ", label: "KZ"},
{id: "LA", label: "LA"},
{id: "LB", label: "LB"},
{id: "LC", label: "LC"},
{id: "LI", label: "LI"},
{id: "LK", label: "LK"},
{id: "LR", label: "LR"},
{id: "LS", label: "LS"},
{id: "LT", label: "LT"},
{id: "LU", label: "LU"},
{id: "LV", label: "LV"},
{id: "LY", label: "LY"},
{id: "MA", label: "MA"},
{id: "MC", label: "MC"},
{id: "MD", label: "MD"},
{id: "ME", label: "ME"},
{id: "MG", label: "MG"},
{id: "MH", label: "MH"},
{id: "MK", label: "MK"},
{id: "ML", label: "ML"},
{id: "MM", label: "MM"},
{id: "MN", label: "MN"},
{id: "MR", label: "MR"},
{id: "MT", label: "MT"},
{id: "MU", label: "MU"},
{id: "MV", label: "MV"},
{id: "MW", label: "MW"},
{id: "MX", label: "MX"},
{id: "MY", label: "MY"},
{id: "MZ", label: "MZ"},
{id: "NA", label: "NA"},
{id: "NC", label: "NC"},
{id: "NE", label: "NE"},
{id: "NG", label: "NG"},
{id: "NI", label: "NI"},
{id: "NL", label: "NL"},
{id: "NO", label: "NO"},
{id: "NP", label: "NP"},
{id: "NR", label: "NR"},
{id: "NZ", label: "NZ"},
{id: "OM", label: "OM"},
{id: "PA", label: "PA"},
{id: "PE", label: "PE"},
{id: "PF", label: "PF"},
{id: "PG", label: "PG"},
{id: "PH", label: "PH"},
{id: "PK", label: "PK"},
{id: "PL", label: "PL"},
{id: "PR", label: "PR"},
{id: "PT", label: "PT"},
{id: "PW", label: "PW"},
{id: "PY", label: "PY"},
{id: "QA", label: "QA"},
{id: "RO", label: "RO"},
{id: "RS", label: "RS"},
{id: "RU", label: "RU"},
{id: "RW", label: "RW"},
{id: "SA", label: "SA"},
{id: "SB", label: "SB"},
{id: "SC", label: "SC"},
{id: "SD", label: "SD"},
{id: "SE", label: "SE"},
{id: "SG", label: "SG"},
{id: "SI", label: "SI"},
{id: "SK", label: "SK"},
{id: "SL", label: "SL"},
{id: "SM", label: "SM"},
{id: "SN", label: "SN"},
{id: "SO", label: "SO"},
{id: "SR", label: "SR"},
{id: "SS", label: "SS"},
{id: "ST", label: "ST"},
{id: "SV", label: "SV"},
{id: "SY", label: "SY"},
{id: "SZ", label: "SZ"},
{id: "TD", label: "TD"},
{id: "TG", label: "TG"},
{id: "TH", label: "TH"},
{id: "TJ", label: "TJ"},
{id: "TL", label: "TL"},
{id: "TM", label: "TM"},
{id: "TN", label: "TN"},
{id: "TO", label: "TO"},
{id: "TR", label: "TR"},
{id: "TT", label: "TT"},
{id: "TV", label: "TV"},
{id: "TW", label: "TW"},
{id: "TZ", label: "TZ"},
{id: "UA", label: "UA"},
{id: "UG", label: "UG"},
{id: "UY", label: "UY"},
{id: "UZ", label: "UZ"},
{id: "VA", label: "VA"},
{id: "VC", label: "VC"},
{id: "VE", label: "VE"},
{id: "VN", label: "VN"},
{id: "VU", label: "VU"},
{id: "WS", label: "WS"},
{id: "YE", label: "YE"},
{id: "ZA", label: "ZA"},
{id: "ZM", label: "ZM"},
{id: "ZW", label: "ZW"},
{id: "FO", label: "FO"},
{id: "GL", label: "GL"},
{id: "VG", label: "VG"},
{id: "UK", label: "UK"},
]

export const filterOperators = [
  {
    operator: FilterOperatorTypes.contains,
    isAnot: false,
    input: [ColumnTypes.string, ColumnTypes.elasticString,ColumnTypes.artWorkString, ColumnTypes.tag],
    icon: <BiFilterAlt />,
  },
  {
    operator: FilterOperatorTypes.notContains,
    isAnot: true,
    input: [ColumnTypes.string, ColumnTypes.elasticString,ColumnTypes.artWorkString,ColumnTypes.tag],
    icon: <BiFilterAlt />,
  },
  {
    operator: FilterOperatorTypes.startsWith,
    isAnot: false,
    input: [ColumnTypes.string],
    icon: <FaTerminal />,
  },
  {
    operator: FilterOperatorTypes.notStartsWith,
    isAnot: true,
    input: [ColumnTypes.string],
    icon: <FaTerminal />,
  },
  {
    operator: FilterOperatorTypes.endsWith,
    isAnot: false,
    input: [ColumnTypes.string],
    icon: <FaFont />,
  },
  {
    operator: FilterOperatorTypes.notEndsWith,
    isAnot: true,
    input: [ColumnTypes.string],
    icon: <FaFont />,
  },
  {
    operator: FilterOperatorTypes.equals,
    input: [
      ColumnTypes.string,
      ColumnTypes.elasticString,
      ColumnTypes.identity,
      ColumnTypes.options,
      ColumnTypes.users,
      ColumnTypes.tag,
      ColumnTypes.number,
      ColumnTypes.datetime,
      ColumnTypes.date,
      ColumnTypes.boolean,
      ColumnTypes.numberAndOption, 
      ColumnTypes.territories_selector, 
      ColumnTypes.artWorkString
    ],
    isAnot: false,
    icon: <FaEquals />,
  },
  {
    operator: FilterOperatorTypes.notEquals,
    input: [
      ColumnTypes.string,
      ColumnTypes.identity,
      ColumnTypes.elasticString,
      ColumnTypes.options,
      ColumnTypes.users,
      ColumnTypes.tag,
      ColumnTypes.number,
      ColumnTypes.datetime,
      ColumnTypes.date,
      ColumnTypes.boolean,
      ColumnTypes.numberAndOption, 
      ColumnTypes.territories_selector, 
      ColumnTypes.artWorkString
    ],
    isAnot: true,
    icon: <FaEquals />,
  },
  {
    operator: FilterOperatorTypes.greaterThan,
    isAnot: false,
    input: [ColumnTypes.number, ColumnTypes.datetime, ColumnTypes.date, ColumnTypes.numberAndOption, ],
    icon: <FaGreaterThan />,
  },
  {
    operator: FilterOperatorTypes.lessThan,
    isAnot: false,
    input: [ColumnTypes.number, ColumnTypes.datetime, ColumnTypes.date, ColumnTypes.numberAndOption, ],
    icon: <FaLessThan />,
  },
  {
    operator: FilterOperatorTypes.greaterThanEqual,
    isAnot: false,
    input: [ColumnTypes.number, ColumnTypes.datetime, ColumnTypes.date, ColumnTypes.numberAndOption, ],
    icon: <FaGreaterThanEqual />,
  },
  {
    operator: FilterOperatorTypes.lessThanEqual,
    isAnot: false,
    input: [ColumnTypes.number, ColumnTypes.datetime, ColumnTypes.date, ColumnTypes.numberAndOption, ],
    icon: <FaLessThanEqual />,
  },
  {
    operator: FilterOperatorTypes.between,
    isAnot: false,
    input: [ColumnTypes.number, ColumnTypes.datetime, ColumnTypes.date, ColumnTypes.numberAndOption, ],
    icon: <FaNetworkWired />,
  },
  {
    operator: FilterOperatorTypes.empty,
    isAnot: false,
    input: [
      ColumnTypes.number, 
      ColumnTypes.datetime, 
      ColumnTypes.date,
      ColumnTypes.string,
      ColumnTypes.elasticString,
      ColumnTypes.numberAndOption, 
      ColumnTypes.territories_selector, 
      ColumnTypes.artWorkString, 
    ],
    icon: <RiCheckboxBlankCircleLine />,
  },
  {
    operator: FilterOperatorTypes.notEmpty,
    input: [
      ColumnTypes.number, 
      ColumnTypes.datetime, 
      ColumnTypes.date,
      ColumnTypes.string,
      ColumnTypes.elasticString,
      ColumnTypes.numberAndOption, 
      ColumnTypes.territories_selector, 
      ColumnTypes.artWorkString, 

    ],
    isAnot: true,
    icon: <RiCheckboxBlankCircleLine />,
  },
  {
    operator: FilterOperatorTypes.option,
    isAnot: false,
    input: [
      ColumnTypes.numberAndOption, 
    ],
    icon: <BsCheck class="filterOption" />,
  },
  // {
  //   operator: FilterOperatorTypes.dateTime,
  //   isAnot: false,
  //   input: [
  //     ColumnTypes.datetime
  //   ],
  //   icon: <MdAccessTime />,
  // },
];

export const filterOperatorsWithDateTime = [
  {
    operator: FilterOperatorTypes.contains,
    input: [ColumnTypes.string, ColumnTypes.elasticString, ColumnTypes.regularString],
    icon: <BiFilterAlt />,
  },
  {
    operator: FilterOperatorTypes.notContains,
    isAnot: true,
    input: [ColumnTypes.string, ColumnTypes.elasticString, ColumnTypes.regularString],
    icon: <BiFilterAlt />,
  },
  {
    operator: FilterOperatorTypes.startsWith,
    isAnot: false,
    input: [ColumnTypes.string],
    icon: <FaTerminal />,
  },
  {
    operator: FilterOperatorTypes.notStartsWith,
    isAnot: true,
    input: [ColumnTypes.string],
    icon: <FaTerminal />,
  },
  {
    operator: FilterOperatorTypes.endsWith,
    isAnot: false,
    input: [ColumnTypes.string],
    icon: <FaFont />,
  },
  {
    operator: FilterOperatorTypes.notEndsWith,
    isAnot: true,
    input: [ColumnTypes.string],
    icon: <FaFont />,
  },
  {
    operator: FilterOperatorTypes.equals,
    input: [
      ColumnTypes.string,
      ColumnTypes.elasticString,
      ColumnTypes.identity,
      ColumnTypes.options,
      ColumnTypes.users,
      ColumnTypes.tag,
      ColumnTypes.number,
      ColumnTypes.datetime,
      ColumnTypes.date,
      ColumnTypes.boolean,
    ],
    isAnot: false,
    icon: <FaEquals />,
  },
  {
    operator: FilterOperatorTypes.notEquals,
    input: [
      ColumnTypes.string,
      ColumnTypes.identity,
      ColumnTypes.elasticString,
      ColumnTypes.options,
      ColumnTypes.users,
      ColumnTypes.tag,
      ColumnTypes.number,
      ColumnTypes.datetime,
      ColumnTypes.date,
      ColumnTypes.boolean,
    ],
    isAnot: true,
    icon: <FaEquals />,
  },
  {
    operator: FilterOperatorTypes.greaterThan,
    isAnot: false,
    input: [ColumnTypes.number, ColumnTypes.datetime, ColumnTypes.date],
    icon: <FaGreaterThan />,
  },
  {
    operator: FilterOperatorTypes.lessThan,
    isAnot: false,
    input: [ColumnTypes.number, ColumnTypes.datetime, ColumnTypes.date],
    icon: <FaLessThan />,
  },
  {
    operator: FilterOperatorTypes.greaterThanEqual,
    isAnot: false,
    input: [ColumnTypes.number, ColumnTypes.datetime, ColumnTypes.date],
    icon: <FaGreaterThanEqual />,
  },
  {
    operator: FilterOperatorTypes.lessThanEqual,
    isAnot: false,
    input: [ColumnTypes.number, ColumnTypes.datetime, ColumnTypes.date],
    icon: <FaLessThanEqual />,
  },
  {
    operator: FilterOperatorTypes.between,
    isAnot: false,
    input: [ColumnTypes.number, ColumnTypes.datetime, ColumnTypes.date],
    icon: <FaNetworkWired />,
  },
  {
    operator: FilterOperatorTypes.empty,
    isAnot: false,
    input: [
      ColumnTypes.number, 
      ColumnTypes.datetime, 
      ColumnTypes.date,
      ColumnTypes.string,
      ColumnTypes.elasticString,
    ],
    icon: <RiCheckboxBlankCircleLine />,
  },
  {
    operator: FilterOperatorTypes.notEmpty,
    isAnot: false,
    input: [
      ColumnTypes.number, 
      ColumnTypes.datetime, 
      ColumnTypes.date,
      ColumnTypes.string,
      ColumnTypes.elasticString
    ],
    isAnot: true,
    icon: <RiCheckboxBlankCircleLine />,
  },
  {
    operator: FilterOperatorTypes.dateTime,
    isAnot: false,
    input: [
      ColumnTypes.datetime
    ],
    icon: <MdAccessTime />,
  }
];

export const filterOperatorsWithoutNew = [
  {
    operator: FilterOperatorTypes.contains,
    input: [ColumnTypes.string, ColumnTypes.elasticString],
    icon: <BiFilterAlt />,
  },
  {
    operator: FilterOperatorTypes.notContains,
    isAnot: true,
    input: [ColumnTypes.string, ColumnTypes.elasticString],
    icon: <BiFilterAlt />,
  },
  {
    operator: FilterOperatorTypes.startsWith,
    isAnot: false,
    input: [ColumnTypes.string],
    icon: <FaTerminal />,
  },
  {
    operator: FilterOperatorTypes.notStartsWith,
    isAnot: true,
    input: [ColumnTypes.string],
    icon: <FaTerminal />,
  },
  {
    operator: FilterOperatorTypes.endsWith,
    isAnot: false,
    input: [ColumnTypes.string],
    icon: <FaFont />,
  },
  {
    operator: FilterOperatorTypes.notEndsWith,
    isAnot: true,
    input: [ColumnTypes.string],
    icon: <FaFont />,
  },
  {
    operator: FilterOperatorTypes.equals,
    input: [
      ColumnTypes.string,
      ColumnTypes.elasticString,
      ColumnTypes.identity,
      ColumnTypes.options,
      ColumnTypes.users,
      ColumnTypes.tag,
      ColumnTypes.number,
      ColumnTypes.datetime,
      ColumnTypes.date,
      ColumnTypes.boolean,
    ],
    isAnot: false,
    icon: <FaEquals />,
  },
  {
    operator: FilterOperatorTypes.notEquals,
    input: [
      ColumnTypes.string,
      ColumnTypes.identity,
      ColumnTypes.elasticString,
      ColumnTypes.options,
      ColumnTypes.users,
      ColumnTypes.tag,
      ColumnTypes.number,
      ColumnTypes.datetime,
      ColumnTypes.date,
      ColumnTypes.boolean,
    ],
    isAnot: true,
    icon: <FaEquals />,
  },
  {
    operator: FilterOperatorTypes.greaterThan,
    isAnot: false,
    input: [ColumnTypes.number, ColumnTypes.datetime, ColumnTypes.date],
    icon: <FaGreaterThan />,
  },
  {
    operator: FilterOperatorTypes.lessThan,
    isAnot: false,
    input: [ColumnTypes.number, ColumnTypes.datetime, ColumnTypes.date],
    icon: <FaLessThan />,
  },
  {
    operator: FilterOperatorTypes.greaterThanEqual,
    isAnot: false,
    input: [ColumnTypes.number, ColumnTypes.datetime, ColumnTypes.date],
    icon: <FaGreaterThanEqual />,
  },
  {
    operator: FilterOperatorTypes.lessThanEqual,
    isAnot: false,
    input: [ColumnTypes.number, ColumnTypes.datetime, ColumnTypes.date],
    icon: <FaLessThanEqual />,
  }
];

export const PossibleElasticStringFilterOperatorTypes = [
  {
    operator: FilterOperatorTypes.contains,
    input: FilterInputTypes.text,
    isAnot: false,
    iconHandler: (isActive) => {
      return <BiFilterAlt style={isActive ? activeStyle : null} />;
    },
  },
  {
    operator: FilterOperatorTypes.notContains,
    input: FilterInputTypes.text,
    isAnot: true,
    iconHandler: (isActive) => {
      return <BiFilterAlt style={isActive ? activeStyle : null} />;
    },
  },
  {
    operator: FilterOperatorTypes.exactly,
    input: FilterInputTypes.text,
    isAnot: false,
    iconHandler: (isActive) => {
      return <FaCheck style={isActive ? activeStyle : null} />;
    },
  },
  {
    operator: FilterOperatorTypes.notExactly,
    input: FilterInputTypes.text,
    isAnot: true,
    iconHandler: (isActive) => {
      return <FaCheck style={isActive ? activeStyle : null} />;
    },
  },
];

export const PossibleStringFilterOperatorTypes = [
  {
    operator: FilterOperatorTypes.contains,
    input: FilterInputTypes.text,
    isAnot: false,
    iconHandler: (isActive) => {
      return <BiFilterAlt style={isActive ? activeStyle : null} />;
    },
  },
  {
    operator: FilterOperatorTypes.notContains,
    input: FilterInputTypes.text,
    isAnot: true,
    iconHandler: (isActive) => {
      return <BiFilterAlt style={isActive ? activeStyle : null} />;
    },
  },
  {
    operator: FilterOperatorTypes.startsWith,
    input: FilterInputTypes.text,
    isAnot: false,
    iconHandler: (isActive) => {
      return <FaTerminal style={isActive ? activeStyle : null} />;
    },
  },
  {
    operator: FilterOperatorTypes.notStartsWith,
    input: FilterInputTypes.text,
    isAnot: true,
    iconHandler: (isActive) => {
      return <FaTerminal style={isActive ? activeStyle : null} />;
    },
  },
  {
    operator: FilterOperatorTypes.endsWith,
    input: FilterInputTypes.text,
    isAnot: false,
    iconHandler: (isActive) => {
      return <FaFont style={isActive ? activeStyle : null} />;
    },
  },
  {
    operator: FilterOperatorTypes.notEndsWith,
    input: FilterInputTypes.text,
    isAnot: true,
    iconHandler: (isActive) => {
      return <FaFont style={isActive ? activeStyle : null} />;
    },
  },
  {
    operator: FilterOperatorTypes.exactly,
    input: FilterInputTypes.text,
    isAnot: false,
    iconHandler: (isActive) => {
      return <FaCheck style={isActive ? activeStyle : null} />;
    },
  },
  {
    operator: FilterOperatorTypes.notExactly,
    input: FilterInputTypes.text,
    isAnot: true,
    iconHandler: (isActive) => {
      return <FaCheck style={isActive ? activeStyle : null} />;
    },
  },
];

export const PossibleNumericFilterOperatorTypes = [
  {
    operator: FilterOperatorTypes.equals,
    input: FilterInputTypes.number,
    isAnot: false,
    iconHandler: (isActive) => {
      return <FaEquals style={isActive ? activeStyle : null} />;
    },
  },
  {
    operator: FilterOperatorTypes.notEquals,
    input: FilterInputTypes.number,
    isAnot: true,
    iconHandler: (isActive) => {
      return <FaEquals style={isActive ? activeStyle : null} />;
    },
  },
  {
    operator: FilterOperatorTypes.greaterThan,
    input: FilterInputTypes.number,
    isAnot: false,
    iconHandler: (isActive) => {
      return <FaGreaterThan style={isActive ? activeStyle : null} />;
    },
  },
  {
    operator: FilterOperatorTypes.lessThan,
    input: FilterInputTypes.number,
    isAnot: false,
    iconHandler: (isActive) => {
      return <FaLessThan style={isActive ? activeStyle : null} />;
    },
  },
  {
    operator: FilterOperatorTypes.greaterThanEqual,
    input: FilterInputTypes.number,
    isAnot: false,
    iconHandler: (isActive) => {
      return <FaGreaterThanEqual style={isActive ? activeStyle : null} />;
    },
  },
  {
    operator: FilterOperatorTypes.lessThanEqual,
    input: FilterInputTypes.number,
    isAnot: false,
    iconHandler: (isActive) => {
      return <FaLessThanEqual style={isActive ? activeStyle : null} />;
    },
  },
];

export const bpmFilterOptionList = [
  {id:'20BPMandbelow', label:'20 BPM and below Larghissimo (very, very slow)', value:"0/20"},
  {id:'20–40BPM', label:'20–40 BPM Grave (slow and solemn)', value:"20/40"},
  {id:'40–60BPM', label:'40–60 BPM Largo (slow)', value:"40/60"},
  {id:'60–66BPM', label:'60–66 BPM Larghetto (quite slow)', value:"60/66"},
  {id:'66–76BPM', label:'66–76 BPM Adagio (at ease)', value:"66/76"},
  {id:'70–80BPM', label:'70–80 BPM Adagietto (rather slow)', value:"70/80"},
  {id:'76–108BPM', label:'76–108 BPM Andante (at a walking pace)', value:"76/108"},
  {id:'108–120BPM', label:'108–120 BPM Moderato (moderately quick)', value:"108/120"},
  {id:'112–124BPM', label:'112–124 BPM Allegro moderato (moderately quick)', value:"112/124"},
  {id:'120–168BPM', label:'120–168 BPM Allegro (heatbeat tempo, sweet spot)', value:"120/168"},
  {id:'168-176BPM', label:'168-176 BPM Vivace (lively and fast)', value:"168/176"},
  {id:'168–200BPM', label:'168–200 BPM Presto (very fast)', value:"168/200"},
  {id:'morethan200BPM', label:'More than 200 BPM Prestissimo (extremely fast)', value:"200/100000"},
];

export const PossibleIdentityFilterOperatorTypes = [
  {
    operator: FilterOperatorTypes.exactly,
    input: FilterInputTypes.text,
    isAnot: false,
    iconHandler: (isActive) => {
      return <FaEquals style={isActive ? activeStyle : null} />;
    },
  },
  {
    operator: FilterOperatorTypes.notExactly,
    input: FilterInputTypes.text,
    isAnot: true,
    iconHandler: (isActive) => {
      return <FaEquals style={isActive ? activeStyle : null} />;
    },
  },
];

export const PossibleOptionsFilterOperatorTypes = [
  {
    operator: FilterOperatorTypes.equals,
    input: FilterInputTypes.options,
    isAnot: false,
    iconHandler: (isActive) => {
      return <FaEquals style={isActive ? activeStyle : null} />;
    },
  },
  {
    operator: FilterOperatorTypes.notEquals,
    input: FilterInputTypes.options,
    isAnot: true,
    iconHandler: (isActive) => {
      return <FaEquals style={isActive ? activeStyle : null} />;
    },
  },
];
