import TableStyles from "../../../styles/TableStyles.css";
import CommonStyles from "../../../styles/CommonStyles.css";

export const styles = (theme) => ({
  ...TableStyles,
  ...CommonStyles,
  selected: {
    fontWeight: theme.typography.fontWeightRegular,
    color: "grey",
  },
  notselected: {
    fontWeight: theme.typography.fontWeightMedium,
    color: "white",
  },
  roleAddPermission:{
    margin:"20px 0px  0px 0px",
    "& .MuiChip-root":{
      fontSize:"inherit !important",
    },
    "& .MuiSelect-select":{
      padding:"10px"
    },
  },
  roledividerMargin:{
    margin:"5px 0px  5px 0px",
  },
  globalGreenButton: {
    background: "#e85570",
    boxShadow: "none",
    color: "#fff",
    border:"1px solid #e85570",
    borderRadius:"5px",
    textTransform: "none",
    fontWeight: "400",
    margin: "0px",
    padding:"5px 7px",
    float: "right",
    cursor:"pointer",
    "&:hover": {
        background: "#000",
        color: "#29D211",
        border:"1px solid #403f3f",
    },
},
moduleControllar:{
  background:"#171717",
  padding:"10px",
  borderRadius:"5px",
  margin: "15px 0 10px 0",
  display: "flex",
    fontSize: "15px",
    alignItems: "center",
    "& span":{
      width:"-webkit-fill-available",
      width:"100%",
    }
},
resultsWeightContainer:{
  display:"flex",
  alignItems: "center",
  "& > span":{
    margin: "10px",
    cursor: "pointer",
    background:" #4e4e4e",
    borderRadius: "3px",
    padding: "5px",
    "& svg":{
      verticalAlign:"middle",
    },
  },
  "& .MuiSelect-select":{
    padding:"10px 5px 10px 5px",
  },
},
moduleEditContainer:{
  display:"flex",
  alignItems: "center",
  fontSize: "0.9em"
}
});
