import { actionTypes } from "../actions/MlAlbumActions";
import { SortTypes } from "../../util/table-view/TableTypes";
import { albumresultViews } from "../../_module/definition/MlAlbumSearch";
// import albumOrigins from "../../_module/definition/albumOrigins";
import albumAllFileds from "../../_module/definition/albumAllFileds";
import {
  _searchAlbumMain,
  _searchAlbumTag,
  _searchAlbumPersonal,
} from "./../../_module/definition/Settings";
import { defaultContentResultsPerPage, defaultResultsPerPage, pagingTotalRelation } from "../Types";
import { appConfig } from "App";

const albumOrigins = window._env_?.ALBUM_MUSIC_ORIGINS;

export const initialState = {
  tagCloud: {
    open: false,
    items: [],
    loading: false,

    query: {
      suffle: false,
      tempFilter: null,
      module: "trackAll",
      size: 50,
      order: SortTypes.desc,
      orderBy: "count",
      tagType: "all",
      filters: [],
    },
    errors: null,
  },
  items: [],
  totalItems: 0,
  totalRelation: pagingTotalRelation.EQUALS,
  selectedItem: {},
  selectedItems: [],
  loading: false,
  //loadingMore: false,
  posting: { busy: false, message: "", errors: null },
  editing: null,
  //noMore: true,
  resultView: albumresultViews.single.id,
  postUpdating: false,
  discreteEnabled: false,
  suggOptionList: [],
  query: {
    advSearch: _searchAlbumMain.id,
    order: SortTypes.asc,
    orderBy: null,
    page: 0,
    size: defaultContentResultsPerPage,
    origin: albumOrigins.originall.val,
    q: "",
    qfs: albumAllFileds,
    filters: [],
    all: { q: "", f: "all" },
    tagAll: { q: "", f: "all" },
    cloudAll: [],
    cloudQuery:{},
    searchId: null,
  },
  errors: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CLOUD_OPEN:
      return {
        ...state,
        tagCloud: { ...state.tagCloud, open: true },
      };

    case actionTypes.CLOUD_SET_PERS_FIELD:
      return {
        ...state,
        tagCloud: {
          ...state.tagCloud,
          query: { ...state.tagCloud.query, tagType: action.payload },
        },
      };

      case actionTypes.SET_SUGGESTIONS_LIST:
        return {
          ...state,
          suggOptionList: action.payload,
        }; 

    case actionTypes.CLOUD_CLOSE:
      return {
        ...state,
        tagCloud: { ...state.tagCloud, open: false },
      };
    case actionTypes.CLOUD_LOADING:
      return {
        ...state,
        tagCloud: { ...state.tagCloud, loading: true, errors: null, items: [] },
      };
    case actionTypes.CLOUD_LOAD:
      return {
        ...state,
        tagCloud: {
          ...state.tagCloud,
          loading: false,
          errors: null,
          items: action.payload,
        },
      };

    case actionTypes.CLOUD_ERROR:
      return {
        ...state,
        tagCloud: {
          ...state.tagCloud,
          loading: false,
          errors: action.payload,
          items: [],
        },
      };

    case actionTypes.CLOUD_QUERY:
      return {
        ...state,
        tagCloud: { ...state.tagCloud, query: action.payload },
      };

    case actionTypes.LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
        selectedItems: [],
        items: [],
      };

    case actionTypes.LOAD:
      return {
        ...state,
        loading: false,
        errors: null,
        items: action.payload,
        totalItems: action.total,
        totalRelation: action.totalRelation,
        selectedItem: action.payload[0] || {},
        selectedItems: [],
        //noMore: false,
      };

    case actionTypes.ERROR:
      return {
        ...state,
        loading: false,
        //loadingMore: false,
        errors: action.payload,
        items: [],
        totalItems: 0,
        totalRelation: pagingTotalRelation.EQUALS,
      };

    case actionTypes.CLEAR:
      return {
        ...state,
        loading: false,
        errors: null,
        selectedItem: {},
        items: [],
        totalItems: 0,
        totalRelation: pagingTotalRelation.EQUALS,
        query: initialState.query,
      };

    case actionTypes.SELECT_ITEMS:
      return {
        ...state,
        selectedItems: action.payload,
      };
    case actionTypes.QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case actionTypes.FOCUS_ITEM:
      return {
        ...state,
        selectedItem: action.payload,
      };
    case actionTypes.RESULT_VIEW:
      return {
        ...state,
        resultView: action.payload,
      };
    case actionTypes.ADV_SEARCH:
      return {
        ...initialState,
        query: {
          ...initialState.query,
          cloudAll: [],
          filters: [],
          ...action.payload,
        },
      };

    case actionTypes.POSTING:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.POST_OK:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: null,
        },
      };

    case actionTypes.POST_ERROR:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: action.payload,
        },
      };

    case actionTypes.SET_MESSAGE:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.CLEAR_MESSAGE:
      return {
        ...state,
        posting: {
          busy: false,
          message: null,
          errors: null,
        },
      };
    case actionTypes.LOAD_QUERY:
      if (!action.payload.advSearch) {
        action.payload.advSearch = _searchAlbumMain.id;
        if (action.payload?.all?.q || action.payload?.filters?.length > 0) {
          action.payload.advSearch = _searchAlbumPersonal.id;
        } else if (action.payload?.tagAll?.q) {
          action.payload.advSearch = _searchAlbumTag.id;
        }
      }

      return {
        ...initialState,
        query: action.payload,
      };

    // case actionTypes.LOAD_QUERY:
    //     let advSearch = _searchAlbumMain.id;
    //     if (action.payload?.all?.q || action.payload?.filters?.length > 0){
    //       advSearch = _searchAlbumPersonal.id;
    //     } else if (action.payload?.tagAll?.q) {
    //       advSearch = _searchAlbumTag.id;
    //     }
    //     return {
    //       ...initialState,
    //       query: action.payload,
    //       advSearch,
    //     };

    default:
      return state;
  }
}
