export default {
  CF_NAME:1, 
  // CF_GENRE:2, 
  // CF_DEADLINE:3, 
  // CF_STATUS:4, 
  CF_PROGRAMMETYPE:5, 
  CF_CLEARANCEFORMTYPE:6, 
  CF_PRODUCERTYPE:7, 
  CF_INPUTGROUP:8, 
  CF_DEPARTMENT:9, 
  CF_CHANNEL:10, 
  // CF_PRODUCTIONCOMPANY:11, 
  // CF_PRODUCTIONCOMPANYCONTACT:12, 
  CF_SERIESTITLE:13, 
  CF_PROMOTIONID:14, 
  CF_PROMOTIONTITLE:15, 
  CF_PROGRAMMETITLE:16, 
  CF_EPISODETITLE:17, 
  CF_PRODNUMBER:18, 
  CF_PRODUCTIONNAME:19, 
  CF_CONTRACTNUMBER:20, 
  CF_EPISODENAME:21, 
  CF_EPISODENUMBER:22, 
  CF_PROMOONEOFFHEADERQ1:23, 
  CF_PROMOONEOFFHEADERQ2:24, 
  CF_PROMOONEOFFHEADERQ2Sub1:25, 
  CF_PROMOONEOFFHEADERQ3:26, 
  CF_PROMOONEOFFHEADERQ3Sub1:27, 
  CF_PROMOONEOFFHEADERQ4:28, 
  CF_PROMOONEOFFHEADERQ4Sub1:29, 
  CF_PROMOONEOFFHEADERQ5:30, 
  CF_PROMOONEOFFHEADERQ5Sub1:31, 
  CF_PROMOONEOFFHEADERQ6:32, 
  CF_PROMOONEOFFHEADERQ7:33, 
  CF_PROMOONEOFFHEADERQ7Sub1:34, 
  CF_PROMOONEOFFHEADERQ8:35, 
  CF_PROMOONEOFFHEADERQ9:36, 
  CF_CHANNELNAME:37,
  CF_PROGRAMMINGHEADERQ1:38,
  CF_PROGRAMMINGHEADERQ2:39,
  CF_PROGRAMMINGHEADERQ3:40,
  CF_PROGRAMMINGHEADERQ4:41,
  CF_PROGRAMMINGHEADERQ5:42,
  CF_PROGRAMMINGHEADERQ6:43,
  CF_PROGRAMMINGHEADERQ7:44,
  // CF_PROGRAMMINGHEADERQ8:45,
  CF_PROGRAMMINGHEADERQ9:46,
  CF_PROGRAMMINGHEADERQ10:47,
  CF_PROGRAMMINGHEADERQ11:48,
  CF_PROGRAMMINGHEADERQ12:49,
  CF_PROGRAMMINGHEADERQ13:50,
  CF_PROGRAMMINGHEADERQ14:51,
  CF_PROGRAMMINGHEADERQ15:52,

  CF_PROMOONEOFFHEADERQ10:53,
  CF_PROMOONEOFFHEADERQ12:54,
  CF_DELIVERYDATE:55,
  CF_TXDATES:56,
  CF_USERGROUP:57,
};