import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Translation } from "react-i18next";
import { _getModuleDef, _newModuleInstance } from "_module/utils/ModuleUtils";
import { withStyles, Chip, CircularProgress } from "@material-ui/core";
import { styles } from "./ModuleEdit.css";
import ModuleEditOptionsMultiSelect from "./ModuleEditOptionsMultiSelect";
import ModuleEditOptionsSingleSelect from "./ModuleEditOptionsSingleSelect";
import i18next from "i18next";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? "lightgreen" : "grey",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  padding: grid,
  // width: 250,
});

class ModuleEditOptions extends Component {
  constructor(props) {
    super();
    this.state = {
      loading: props.moduleDef.sysCall ? true : false,
      options: props.moduleDef.sysCall
      ? []
      : typeof props.moduleDef.options === "function"
      ? props.moduleDef.options()
      : props.moduleDef.options
    };
  }

  componentDidMount() {
    const { loading } = this.state;
    if (loading) {
      const { moduleDef : {sysCall, sysCallParam} } = this.props;

      sysCall(sysCallParam).then((v) => {
        let newoptions = {};
        v.map((setting) => {
          newoptions[setting.id] = setting;
        });
        this.setState({
          loading: false,
          options: newoptions,
        });
      });
    }
  }


  handleSelect = (value) => {
    let m = this.props.module;
    m.v = value;
    this.props.handleChildChange(this.props.moduleDef.id, m);
  };

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    let m = this.props.module;
    m.v = reorder(m.v, result.source.index, result.destination.index);
    this.props.handleChildChange(this.props.moduleDef.id, m);
  }

  handleArchived = (id)=>{
    var { module } = this.props;
    const currentIndex = module.v.findIndex((v) => {    
      return v == id;
    });
    module.v.splice(currentIndex, 1);
    this.handleSelect(module.v);
  }

  render() {
    var { module, moduleDef, multiple, ordered, classes } = this.props;
    var {options,loading} = this.state;
    
    const getLabale = (v) => {
      return moduleDef.sysCall ? (options[v] || {name : "Error"} ).name : options && options[v] && options[v].label? options[v].label  : i18next.t(`modules.${v}`)
    }

    return (
      <Translation>
        {(t, { i18n }) => (
           <>
           {loading && <div><CircularProgress/></div>}
           {!loading &&   <div>
            {multiple && !ordered ? (
              <div className={classes.root} style={getListStyle(false)}>
                {module.v.map((id) => {
                  return (
                    <Chip
                      style={getItemStyle(false)}
                      key={id}
                      variant="outlined"
                      size="small"
                      label={getLabale(id)}
                    />
                  );
                })}

                <ModuleEditOptionsMultiSelect
                  options={this.state.options}
                  sysCall={moduleDef.sysCall}
                  selected={module.v}
                  handleChange={(v) => this.handleSelect(v)}
                />
              </div>
            ) : multiple && ordered ? (
              <DragDropContext onDragEnd={(result) => this.onDragEnd(result)}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      className={classes.root}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {module.v.map((m, index) => (m in this.state.options) ? (
                        <Draggable
                          key={m}
                          draggableId={m}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <Chip
                              key={m}
                              variant="outlined"
                              size="small"
                              label={getLabale(m)}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            />
                          )}
                        </Draggable>
                      ):(
                        <Draggable
                          key={m}
                          draggableId={m}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <Chip
                              key={m}
                              variant="outlined"
                              size="small"
                              label={getLabale(m)}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                              onDelete={()=>this.handleArchived(m)}
                            />
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}

                      <ModuleEditOptionsMultiSelect
                        options={this.state.options}
                        sysCall={moduleDef.sysCall}
                        selected={module.v}
                        handleChange={(v) => this.handleSelect(v)}
                      />
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <div className={classes.root} style={getListStyle(false)}>
                <ModuleEditOptionsSingleSelect
                  options={this.state.options}
                  sysCall={moduleDef.sysCall}
                  selected={module?.v}
                  isRemovable={moduleDef?.removable || false}
                  handleChange={(v) => this.handleSelect(v)}
                />
              </div>
            )}
          </div>
           
           }

           </>
        
        )}
      </Translation>
    );
  }
}

export default withStyles(styles)(ModuleEditOptions);
