import { appConfig } from "App";
import axios from "axios";
import store from "redux/Store";
import { textLengths } from "../../redux/Types";

export const validateClearanceWizard = async (data) => {
  let errors = {};
  if (data) {

    let validateArray = data.fieldsToValidate || [];
    // let validateArray =  [];
    if (validateArray.findIndex(d => d.id === "programmeType") !== -1 && !data.programmeType) {
      errors.programmeType = "Programme Type should not be empty.";
    } else if (data.programmeType && data.programmeType === appConfig.CLEARANCE.PROGRAMME_TYPES.EPISODE && !data.selectedMasterForm) {
      if (data.id && !data.seriesTitle) {
        errors.programmeType = "Select a series to proceed.";
      } else if (!data.id) {
        errors.programmeType = "Select a series to proceed.";
      } else if (data.id && data.seriesTitle && errors.programmeType) {
        delete errors.programmeType
      }
    }

    if (validateArray.length > 0) {
      for (let index = 0; index < validateArray.length; index++) {
        let item = validateArray[index];

        if (item && item.required) {
          if (
            !data[item.id] ||
            (item.type === "dateRange" && (!Array.isArray(data[item.id]) || !data[item.id].length)) ||
            ((item.type === "text" || item.type === "selfSearch") && data[item.id].trim() === "")
          ) {
            errors[item.id] = `Should not be empty`;
          }
        }
        if (item.subQuestions && item.subQuestions.length > 0) {
          item.subQuestions.forEach(sItem => {
            if (sItem && sItem.required) {

              if (
                !data[sItem.id] ||
                (sItem.type === "dateRange" && (!Array.isArray(data[sItem.id]) || !data[sItem.id].length)) ||
                ((sItem.type === "text" || sItem.type === "selfSearch") && data[sItem.id].trim() === "")
              ) {

                if (sItem.visibleOn && sItem.visibleOn.length > 0) {
                  sItem.visibleOn.map(v => {
                    if (v.operator && v.operator === "AND") {
                      if (v.visibleCondition.map(c => data[c.key] === c.value).every(k => k)) {
                        errors[sItem.id] = `Should not be empty`;
                      }
                    } else if (data[v.key] === v.value) {
                      errors[sItem.id] = `Should not be empty`;
                    }
                  })
                }

              }

            }
            // text length check of sub text items
            if (data[sItem.id] && (sItem.type === "text" || sItem.type === "selfSearch") && data[sItem.id].length > (item.maxLength ?? textLengths.TEXT)) {
              errors[sItem.id] = `Should be less than ${(item.maxLength ?? textLengths.TEXT)} characters.`;
            }
          });
        }
        // text length check
        if (data[item.id] && (item.type === "text" || item.type === "selfSearch") && data[item.id].length > (item.maxLength ?? textLengths.TEXT)) {
          errors[item.id] = `Should be less than ${(item.maxLength ?? textLengths.TEXT)} characters.`;
        }

        if (item.uniqueValue && !errors[item.id]) {
          let isUnique = await validateUniqueValue(item, data[item.id], data.id ? data.id : "") ?? false;
          if (!isUnique) {
            errors[item.id] = `${item?.label} already in use`;
          }
        }


      }
    }

  }
  if (Object.entries(errors).length === 0) {
    return null;
  } else {
    return errors;
  }
};

export const validateUniqueValue = (field, value, cfId, done = null) =>{
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  
return new Promise((resolve)=>{
    axios
    .post(
      `${orgID}/mllic/forms/checkCFUniqueValue`,
      { cfId: cfId, field: field.id, value: value }
  )
    .then((res) => {
      resolve(res.data);
    })
    .catch((err) => {
      resolve(true);
    });
});


}