import React, { useEffect } from "react";
import store from "redux/Store";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { actionTypes } from "../../redux/actions/UserActions";
import Cookies from "js-cookie";

const ToUrl = () => {
  const history = useHistory();
  const login = (o) => {
    console.log("ToUrl history.replace origin", o);
    window.location.replace("https://" + o + "/signin");
    return;
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const orgID = decodeURIComponent(atob(urlParams.get("org")));
    const url = urlParams.get("url");
    const origin = urlParams.get("origin");
    const email = decodeURIComponent(atob(urlParams.get("user")));
    const token = Cookies.get("FBIdToken");

    if (token) {
      axios
        .post("/tokenlogin", { token: token, email: email })
        .then((res) => {
          console.log("tokenlogin", res);
          let user = res.data.user;
          // user.credentials.orgID = orgID;
          const FBIdToken = `Bearer ${res.data.token}`;
          localStorage.setItem("FBIdToken", FBIdToken);
          localStorage.setItem("UserLoggedIn", JSON.stringify(user));
          axios.defaults.headers.common["Authorization"] = token;
          store.dispatch({
            type: actionTypes.SET_USER,
            payload: user,
          });
          history.replace(url || "/pl-promo");
          return;
        })
        .catch((err) => {
          console.log(err);
          login(origin);
          return;
        });
    } else {
      console.log("otherwise");
      login(origin);
      return;
    }

    return;
  }, []);

  return <></>;
};

export default ToUrl;
