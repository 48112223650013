import {
  T_LeafBool,
  T_LeafOptionsMultiOrdered,
  T_Major,
} from "./ModulePropTypes";
import { PT_Setting } from "./ModulePermitTypes";
import albumFields from "./albumFields";

export const _settingAlbumSearchShowUserPref = {
  id: "asuf1",
  permitType: PT_Setting,
  type: T_LeafBool,
  default: false,
};

export const _settingAlbumSearchUserPrefFields = {
  id: "asuf11",
  permitType: PT_Setting,
  type: T_LeafOptionsMultiOrdered,
  options: () => {
    let nonetagtype = {};
    Object.keys(albumFields).map((key) => {
      if (!albumFields[key].sys && !albumFields[key].skipUserPreff) nonetagtype[key] = albumFields[key];
    });
    return nonetagtype;
  },
  default: [
    albumFields.catNo.id,
    albumFields.prodName.id,
    albumFields.prodArtist.id,
    albumFields.ipRecordLabel.id,
    albumFields.prodYear.id,
    albumFields.prodRelease.id,
    albumFields.prodNumberOfDiscs.id,
    albumFields.prodDiscNr.id,
    albumFields.musicOrigin.id,
    albumFields.prodTags.id,
    albumFields.trackTempo.id,
    albumFields.trackBpm.id,
    albumFields.trackGenres.id,
    albumFields.trackInstruments.id,
    albumFields.trackStyles.id,
    albumFields.upc.id,
    albumFields.ean.id,
   
    albumFields.trackTitle.id,
    albumFields.trackIsrc.id,
    albumFields.trackIswc.id,
    albumFields.trackPrs.id,
    albumFields.trackComposer.id,
    albumFields.trackLyricist.id,
    albumFields.trackAdaptor.id,
    albumFields.trackAdministrator.id,
    albumFields.trackArranger.id,
    albumFields.trackPublisher.id,
    albumFields.trackOriginalPublisher.id,
    albumFields.trackPerformer.id,
    albumFields.trackRecordLabel.id,
    albumFields.trackSubLyricist.id,
    albumFields.trackSubAdaptor.id,
    albumFields.trackSubArranger.id,
    albumFields.trackSubPublisher.id,
    albumFields.trackTranslator.id,
  
    albumFields.libNotes.id,
    albumFields.prodNotes.id,
    albumFields.trackNotes.id,
   
    albumFields.libId.id,
    albumFields.libName.id,
    albumFields.wsId.id,
    albumFields.wsName.id,
  ],
};

export const _settingAlbumSearchUserPrefCanSave = {
  id: "asuf2",
  permitType: PT_Setting,
  type: T_LeafBool,
  default: true,
};

export const _settingAlbumMusicSearchPref = {
  id: "settingAlbumMusicSearchPref",
  permitType: PT_Setting,
  type: T_Major,
  modules: [
    _settingAlbumSearchShowUserPref,
    _settingAlbumSearchUserPrefFields,
    _settingAlbumSearchUserPrefCanSave,
  ],
};
