import { _newModuleInstance } from "../utils/newModuleInstance";
import orgSettingType from "./orgSettingType";
import { _settingMusicResult } from "./SettingMusicResult";
import { _settingAlbumMusicResult } from "./SettingAlbumMusicResult";
import { _settingMusicSearch } from "./SettingMusicSearch";
import { _settingAlbumMusicSearch } from "./SettingAlbumMusicSearch";
import { _settingMusicSearchPref } from "./SettingMusicSearchUserPref";
import { _settingAlbumMusicSearchPref } from "./SettingAlbumMusicSearchUserPref";
import trackFields from "./trackFields";
import albumFields from "./albumFields";
import {
  _settingMetadataConditional,
  _settingMetadataFieldsDefault,
  _settingMetadataFieldsCommercial,
  _settingMetadataFieldsLibrary,
  _settingMetadataFieldsCommissioned,
} from "./SettingMusicMetadata";
// import albumOrigins from "./albumOrigins";
import { _settingAlbumMetadataConditional, _settingAlbumMetadataFieldsCommercial, _settingAlbumMetadataFieldsCommissioned, _settingAlbumMetadataFieldsDefault, _settingAlbumMetadataFieldsLibrary } from "./SettingAlbumMetadata";
// import trackOrigins from "./trackOrigins";
import { appConfig } from "App";

const albumOrigins = window._env_?.ALBUM_MUSIC_ORIGINS; //App is not rendered when definition files are executed. so access the config variables through window._env 
const trackOrigins = window._env_?.TRACK_MUSIC_ORIGINS;

export const _newSetting = (type) => {
  switch (type) {
    case orgSettingType.ssearch.id:
      return _newSearchTypeSetting();
    case orgSettingType.asearch.id:
      return _newAlbumSearchTypeSetting();
    case orgSettingType.trackmetadata.id:
      return _newMetadataTypeSetting();
   case orgSettingType.albummetadata.id:
      return _newAlbumMetadataTypeSetting()
   default:
      return _newSearchTypeSetting();
  }
};

export const _newSearchTypeSetting = () => [
  _newModuleInstance(_settingMusicSearch),
  _newModuleInstance(_settingMusicResult),
  _newModuleInstance(_settingMusicSearchPref),
];

export const _newMetadataTypeSetting = () => [
  _newModuleInstance(_settingMetadataConditional),
  _newModuleInstance(_settingMetadataFieldsDefault),
  _newModuleInstance(_settingMetadataFieldsCommercial),
  _newModuleInstance(_settingMetadataFieldsLibrary),
  _newModuleInstance(_settingMetadataFieldsCommissioned),
];


export const _newAlbumMetadataTypeSetting = () => [
  _newModuleInstance(_settingAlbumMetadataConditional),
  _newModuleInstance(_settingAlbumMetadataFieldsDefault),
  _newModuleInstance(_settingAlbumMetadataFieldsCommercial),
  _newModuleInstance(_settingAlbumMetadataFieldsLibrary),
  _newModuleInstance(_settingAlbumMetadataFieldsCommissioned),
];


export const _searchMain = {id: "1",
  name: "Main Search",
  type: orgSettingType.ssearch.id,
  setting: [
    {
      id: "settingMusicSearch",
      ss0: { id: "ss0", v: true },
      ss1: { id: "ss1", v: false },
      ss2: { id: "ss2", v: false },
      ss3: {
        id: "ss3",
        v: Object.keys(trackOrigins),
      },
      ss4: { id: "ss4", v: [] },
    },
    {
      id: "settingMusicResult",
      sr1: {
        id: "sr1",
        v: [
          "trackTitle",
          "performer",
          "isrc",
          "prodName",
          trackFields.catNo.id,
        ],
      },
      sr2: {
        id: "sr2",
        v: [
          "trackTitle",
          trackFields.id.id,
          "performer",
          "isrc",
          "prodName",
          trackFields.catNo.id,
          trackFields.composer.id,
          trackFields.publisher.id,
          trackFields.libName.id,
          trackFields.recordLabel.id,
          trackFields.iswc.id,
          trackFields.prs.id,
          trackFields.duration.id,
          trackFields.position.id,
          trackFields.prodYear.id,

          trackFields.genres.id,
          trackFields.styles.id,
          trackFields.moods.id,
          trackFields.instruments.id,
          trackFields.bpm.id,
          trackFields.tempo.id,
          trackFields.keywords.id,
          trackFields.trackNotes.id,
          trackFields.prodName.id
        ],
      },
    },
    {
      id: "settingMusicSearchPref",
      suf1: {
        id: "suf1",
        v: false,
      },
    },
  ],
};

export const _searchTag = {
  id: "2",
  name: "Tags",
  type: orgSettingType.ssearch.id,
  setting: [
    {
      id: "settingMusicSearch",
      ss0: { id: "ss0", v: true },
      ss1: { id: "ss1", v: false },
      ss2: { id: "ss2", v: true },
      ss11: {
        id: "ss11",
        v: [],
      },
      ss21: {
        id: "ss21",
        v: [
          trackFields.genres.id,
          trackFields.styles.id,
          trackFields.moods.id,
          trackFields.instruments.id,
          trackFields.bpm.id,
          trackFields.tempo.id,
          trackFields.keywords.id,
          trackFields.trackNotes.id,
          trackFields.prodNotes.id,
        ],
      },
      ss3: {
        id: "ss3",
        v:  Object.keys(trackOrigins),
      },
      ss4: { id: "ss4", v: [] },
    },
    {
      id: "settingMusicResult",
      sr1: {
        id: "sr1",
        v: [
          "trackTitle",
          "performer",
          "isrc",
          "prodName",
          trackFields.catNo.id,
        ],
      },
      sr2: {
        id: "sr2",
        v: [
          "trackTitle",
          trackFields.id.id,
          "performer",
          "isrc",
          "prodName",
          trackFields.catNo.id,
          trackFields.composer.id,
          trackFields.publisher.id,
          trackFields.libName.id,
          trackFields.recordLabel.id,
          trackFields.iswc.id,
          trackFields.prs.id,
          trackFields.duration.id,
          trackFields.position.id,
          trackFields.prodYear.id,

          trackFields.genres.id,
          trackFields.styles.id,
          trackFields.moods.id,
          trackFields.instruments.id,
          trackFields.bpm.id,
          trackFields.tempo.id,
          trackFields.keywords.id,
          trackFields.trackNotes.id,
          trackFields.prodNotes.id,
        ],
      },
    },
    {
      id: "settingMusicSearchPref",
      suf1: {
        id: "suf1",
        v: false,
      },
      suf11: {
        id: "suf11",
        v: [],
      },
      suf2: {
        id: "suf2",
        v: true,
      },
    },
  ],
};

export const _searchPersonal = {
  id: "3",
  name: "Personal",
  type: orgSettingType.ssearch.id,
  setting: [
    {
      id: "settingMusicSearch",
      ss0: { id: "ss0", v: true },
      ss1: { id: "ss1", v: false },
      ss2: { id: "ss2", v: false },
      ss11: {
        id: "ss11",
        v: [],
      },
      ss21: { id: "ss21", v: [] },
      ss3: {
        id: "ss3",
        v: Object.keys(trackOrigins),
      },
      ss4: { id: "ss4", v: [] },
    },
    {
      id: "settingMusicResult",
      sr1: {
        id: "sr1",
        v: ["trackTitle", "performer"],
      },
      sr2: {
        id: "sr2",
        v: ["trackTitle", "performer"],
      },
    },
    {
      id: "settingMusicSearchPref",
      suf1: {
        id: "suf1",
        v: true,
      },
      suf11: {
        id: "suf11",
        v: [
          //TODO: ADD FIELDS
          trackFields.id.id,
          trackFields.trackTitle.id,
          trackFields.libName.id,
          trackFields.prodName.id,
          trackFields.catNo.id,

          trackFields.isrc.id,
          trackFields.iswc.id,
          trackFields.prs.id,
          trackFields.alternativeTitle.id,
          trackFields.trackVersionTitle.id,
          trackFields.duration.id,
          trackFields.position.id,
          trackFields.subIndex.id,

          trackFields.performer.id,
          trackFields.composer.id,
          trackFields.arranger.id,
          trackFields.publisher.id,
          trackFields.translator.id,
          trackFields.recordLabel.id,
          trackFields.lyricist.id,

          trackFields.prodYear.id,
          trackFields.prodRelease.id,
          trackFields.prodNumberOfDiscs.id,
          trackFields.prodDiscNr.id,

          trackFields.genres.id,
          trackFields.styles.id,
          trackFields.moods.id,
          trackFields.instruments.id,
          trackFields.bpm.id,
          trackFields.tempo.id,
          trackFields.keywords.id,
          trackFields.trackNotes.id,
          trackFields.prodNotes.id,
          trackFields.wsId.id,
          trackFields.wsName.id,
          trackFields.dateCreated.id,
          trackFields.dateLastEdited.id,
        ],
      },
      suf2: {
        id: "suf2",
        v: true,
      },
    },
  ],
};


export const _metadataMain = {
  id: "4",
  name: "Default Track Metadata",
  type: orgSettingType.trackmetadata.id,
  setting : [
    {
       "id":"sm1",
       "v":true
    },
    {
       "id":"sm2",
       "v":[
          "id",
          "trackTitle",
          "libName",
          "prodName",
          "catNo",
          "musicOrigin",
          "isrc",
          "iswc",
          "prs",
          "alternativeTitle",
          "trackVersionTitle",
          "duration",
          "position",
          "subIndex",
          "performer",
          "composer",
          "arranger",
          "publisher",
          "translator",
          "recordLabel",
          "lyricist",
          "prodYear",
          "prodRelease",
          "prodNumberOfDiscs",
          "prodDiscNr",
          "genres",
          "styles",
          "moods",
          "instruments",
          "bpm",
          "tempo",
          "keywords",
          "trackNotes",
          "prodNotes",
          "arid",
          //"agids"
          "wsId",
          "wsName",

       ]
    },
    {
       "id":"sm4",
       "v":[
          "musicOrigin",
          "trackTitle",
          "performer",
          "isrc",
          "recordLabel",
          "prodName",
          "catNo",
          "publisher",
          "composer",
          "prs",
          "prodYear",
          "trackNotes",
          "prodNotes",
       ]
    },
    {
       "id":"sm3",
       "v":[
          "musicOrigin",
          "trackTitle",
          "prodName",
          "catNo",
          "libName",
          "composer",
          "publisher",
          "prs",
          "genres",
          "styles",
          "moods",
          "instruments",
          "bpm",
          "tempo",
          "keywords",
          "trackNotes",
          "prodNotes",
       ]
    },
    {
       "id":"sm5",
       "v":[
          "musicOrigin",
          "trackTitle",
          "composer",
          "performer",
          "prs",
          "genres",
          "styles",
          "moods",
          "instruments",
          "bpm",
          "tempo",
          "keywords",
          "trackNotes",
          "prodNotes",
       ]
    }
 ]
}

// ALBUM SEARCH

export const _newAlbumSearchTypeSetting = () => [
  _newModuleInstance(_settingAlbumMusicSearch),
  _newModuleInstance(_settingAlbumMusicResult),
  _newModuleInstance(_settingAlbumMusicSearchPref),
];

export const _searchAlbumMain = {
  id: "5",
  name: "Main Search",
  type: orgSettingType.asearch.id,
  setting: [
    {
      id: "settingAlbumMusicSearch",
      ass0: { id: "ass0", v: true },
      ass1: { id: "ass1", v: false },
      ass2: { id: "ass2", v: false },
      ass3: {
        id: "ass3",
        v:  Object.keys(albumOrigins),
      },
      ass4: { id: "ass4", v: [] },
    },
    {
      id: "settingAlbumMusicResult",
      asr1: {
        id: "asr1",
        v: [
          albumFields.catNo.id,
          albumFields.prodName.id,
          albumFields.prodArtist.id,
          albumFields.ipRecordLabel.id,
          albumFields.trackPerformer.id,
        ],
      },
      asr2: {
        id: "asr2",
        v: [
          albumFields.catNo.id,
    albumFields.prodName.id,
    albumFields.prodArtist.id,
    albumFields.ipRecordLabel.id,
    albumFields.trackPerformer.id,
    albumFields.prodYear.id,
    albumFields.prodRelease.id,
    albumFields.prodNumberOfDiscs.id,
    albumFields.prodDiscNr.id,
    albumFields.musicOrigin.id,
    albumFields.prodTags.id,
    albumFields.trackTempo.id,
    albumFields.trackBpm.id,
    albumFields.trackGenres.id,
    albumFields.trackInstruments.id,
    albumFields.trackStyles.id,
    albumFields.upc.id,
    albumFields.ean.id,
        ],
      },
    },
    {
      id: "settingAlbumMusicSearchPref",
      asuf1: {
        id: "asuf1",
        v: false,
      },
    },
  ],
};

export const _searchAlbumTag = {
  id: "6",
  name: "Tags",
  type: orgSettingType.asearch.id,
  setting: [
    {
      id: "settingAlbumMusicSearch",
      ass0: { id: "ass0", v: true },
      ass1: { id: "ass1", v: false },
      ass2: { id: "ass2", v: true },
      ass21: {
        id: "ass21",
        v: [
          albumFields.trackGenres.id,
          albumFields.trackInstruments.id,
          albumFields.trackStyles.id,
          albumFields.trackMoods.id,
          albumFields.trackKeywords.id,
          albumFields.prodTags.id,
          albumFields.trackTempo.id,
          albumFields.trackBpm.id,
          albumFields.trackNotes.id,
          albumFields.prodNotes.id,
          albumFields.libNotes.id,
        ],
      },
      ass3: {
        id: "ass3",
        v: Object.keys(albumOrigins),
      },
      ass4: { id: "ass4", v: [] },
    },
    {
      id: "settingAlbumMusicResult",
      asr1: {
        id: "asr1",
        v: [
          albumFields.catNo.id,
    albumFields.prodName.id,
    albumFields.prodArtist.id,
    albumFields.ipRecordLabel.id,
    albumFields.trackPerformer.id,
        ],
      },
      asr2: {
        id: "asr2",
        v: [
          albumFields.catNo.id,
          albumFields.prodName.id,
          albumFields.prodArtist.id,
          albumFields.ipRecordLabel.id,
          albumFields.trackPerformer.id,
          albumFields.prodYear.id,
          albumFields.prodRelease.id,
          albumFields.prodNumberOfDiscs.id,
          albumFields.prodDiscNr.id,
          albumFields.musicOrigin.id,
          albumFields.prodTags.id,
          albumFields.trackTempo.id,
          albumFields.trackBpm.id,
          albumFields.trackGenres.id,
          albumFields.trackInstruments.id,
          albumFields.trackStyles.id,
          albumFields.upc.id,
          albumFields.ean.id,
        ],
      },
    },
    {
      id: "settingAlbumMusicSearchPref",
      asuf1: {
        id: "asuf1",
        v: false,
      }
    },
  ],
};

export const _searchAlbumPersonal = {
  id: "7",
  name: "Personal",
  type: orgSettingType.asearch.id,
  setting: [
    {
      id: "settingAlbumMusicSearch",
      ass0: { id: "ass0", v: true },
      ass1: { id: "ass1", v: false },
      ass2: { id: "ass2", v: false },
      ass3: {
        id: "ass3",
        v: Object.keys(albumOrigins),
      },
      ass4: { id: "ass4", v: [] },
    },
    {
      id: "settingAlbumMusicResult",
      asr1: {
        id: "asr1",
        v: [albumFields.catNo.id,
          albumFields.prodName.id,
          albumFields.prodArtist.id,
          albumFields.ipRecordLabel.id,
          albumFields.trackPerformer.id,],
      },
      asr2: {
        id: "asr2",
        v: [albumFields.catNo.id,
          albumFields.prodName.id,
          albumFields.prodArtist.id,
          albumFields.ipRecordLabel.id,
          albumFields.trackPerformer.id,
          albumFields.prodYear.id,
          albumFields.prodRelease.id,
          albumFields.prodNumberOfDiscs.id,
          albumFields.prodDiscNr.id,
          albumFields.musicOrigin.id,
          albumFields.prodTags.id,
          albumFields.trackTempo.id,
          albumFields.trackBpm.id,
          albumFields.trackGenres.id,
          albumFields.trackStyles.id,
          albumFields.trackInstruments.id,
          albumFields.upc.id,
          albumFields.ean.id,],
      },
    },
    {
      id: "settingAlbumMusicSearchPref",
      asuf1: {
        id: "asuf1",
        v: true,
      },
      asuf11: {
        id: "asuf11",
        v: [
         
          albumFields.catNo.id,
    albumFields.prodName.id,
    albumFields.prodArtist.id,
    albumFields.ipRecordLabel.id,
    albumFields.prodYear.id,
    albumFields.prodRelease.id,
    albumFields.prodNumberOfDiscs.id,
    albumFields.prodDiscNr.id,
    albumFields.musicOrigin.id,
    albumFields.prodTags.id,
    albumFields.trackTempo.id,
    albumFields.trackBpm.id,
    albumFields.trackGenres.id,
    albumFields.trackInstruments.id,
    albumFields.trackStyles.id,
    albumFields.upc.id,
    albumFields.ean.id,
   
    albumFields.trackTitle.id,
    albumFields.trackIsrc.id,
    albumFields.trackIswc.id,
    albumFields.trackPrs.id,
    albumFields.trackComposer.id,
    albumFields.trackLyricist.id,
    albumFields.trackAdaptor.id,
    albumFields.trackAdministrator.id,
    albumFields.trackArranger.id,
    albumFields.trackPublisher.id,
    albumFields.trackOriginalPublisher.id,
    albumFields.trackPerformer.id,
    albumFields.trackRecordLabel.id,
    albumFields.trackSubLyricist.id,
    albumFields.trackSubAdaptor.id,
    albumFields.trackSubArranger.id,
    albumFields.trackSubPublisher.id,
    albumFields.trackTranslator.id,
  
    albumFields.libNotes.id,
    albumFields.prodNotes.id,
    albumFields.trackNotes.id,
   
    albumFields.libId.id,
    albumFields.libName.id,
    albumFields.wsId.id,
    albumFields.wsName.id,
        ],
      },
      asuf2: {
        id: "asuf2",
        v: true,
      },
    },
  ],
};



export const settings = [_searchMain, _searchPersonal, _searchTag,
  _metadataMain
];

export const shortCutKeys = "alt+s,alt+p,alt+c,alt+o,alt+l,alt+w,alt+r,alt+u,alt+t";
