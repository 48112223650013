import { _mlLicensingUserGroupPermission } from "redux/Types";
import { __trackStatus } from "../../_clearance/definition/status";
import { PT_Org } from "./ModulePermitTypes";
import { T_Major, T_LeafBool, T_LeafOptionsSingle, T_LeafOptionsMulti, T_LeafOptionsMultiOrdered } from "./ModulePropTypes";


export const _mlLicensingWorkflowPermissionOptions = {
  default: { id: "default", name: "Default" },
  allAdmin: { id: "allAdmin", name: "All Admin" },
  musicRightsAdmin: { id: "musicRightsAdmin", name: "Music Rights Admin" },
};
export const _mlLicensingDefinitiontoOwnOptions = {
  user: { id: "user", name: "User" },
  userGroup: { id: "userGroup", name: "User Group" },
  externalProductionCompany: { id: "externalProductionCompany", name: "External Production Company" },
};

export const _mlLicensingUserGroupPermissionOptions = {
  [_mlLicensingUserGroupPermission.ALL] : { id: _mlLicensingUserGroupPermission.ALL, name: "All" },
  [_mlLicensingUserGroupPermission.OWN]: { id: _mlLicensingUserGroupPermission.OWN, name: "Own" },
};



export const _mlLicensingCanCreate = {
  id: "mlL1",
  type: T_LeafBool,
  default: true,
};

export const _mlLicensingCanRunWizard = {
  id: "mlL2",
  type: T_LeafBool,
  default: true,
};

export const _mlLicensingFormAll = {
  id: "mlL3",
  type: T_LeafBool,
  default: true,
};

export const _mlLicensingTrackAll = {
  id: "mlL5",
  type: T_LeafBool,
  default: true,
};

export const _mlLicensingOverideRestrictions = {
  id: "mlL4",
  type: T_LeafBool,
  default: true,
};

export const _mlLicensingWorkflowAdmin = {
  id: "mlL6",
  type: T_LeafBool,
  default: false,
};

export const _mlLicensingWorkflowPermission = {
  id: "mlL7",
  type: T_LeafOptionsSingle,
  options: _mlLicensingWorkflowPermissionOptions,
  default: _mlLicensingWorkflowPermissionOptions.default.id,
};

export const _mlLicensingCanAddToPPL = {
  id: "mlL8",
  type: T_LeafBool,
  default: false,
};

export const _mlLicensingDefinitiontoOwn = {
  id: "mlL9",
  type: T_LeafOptionsSingle,
  options: _mlLicensingDefinitiontoOwnOptions,
  default: _mlLicensingDefinitiontoOwnOptions.user.id,
};

export const _mlLicensingUserGroupList = {
  id: "mlL10",
  type: T_LeafOptionsSingle,
  options: _mlLicensingUserGroupPermissionOptions,
  default: _mlLicensingUserGroupPermission.OWN,
};

export const _mlLicensingCFTypeUserGroupRestrictions = {
  id: "mlL11",
  type: T_LeafBool,
  default: false,
};

export const _mlLicensing = {
  id: "mlLicensing",
  permitType: PT_Org,
  type: T_Major,
  modules: [
    _mlLicensingCanCreate,
    _mlLicensingCanRunWizard,
    _mlLicensingFormAll,
    _mlLicensingTrackAll,
    _mlLicensingOverideRestrictions,
    // _mlLicensingWorkflowAdmin,
    _mlLicensingCanAddToPPL,
    _mlLicensingWorkflowPermission,
    _mlLicensingDefinitiontoOwn,
    _mlLicensingUserGroupList,
    // _mlLicensingCFTypeUserGroupRestrictions //temporary commented, awaiting confirmation to be released 
  ],
};

