import axios from "axios";
import store from "redux/Store";
module = "/searchPrs";


export const actionTypes = {
  POSTING: "POSTING" + module,
  POSTING_OK: "POSTINGOK" + module,
  POST_OK: "POSTOK" + module,
  POSTING_ERROR: "POSTING_ERROR" + module,
  POST_ERROR: "POST_ERROR" + module,
  CLEAR_ERRORS: "CLEAR_ERRORS" + module,
};


export const postItem = ( trackId, done = null) => (dispatch) => {
  
  const {
    user: {
      credentials: { orgID },
    }
  } = store.getState();

  dispatch({
    type: actionTypes.POSTING,
    payload: 'Matching with PRS...',
  });


  axios
  .get(`${orgID}/praManualMatch/${trackId}`)
  .then((res) => {
    
      dispatch({
        type: actionTypes.POSTING_OK,
        payload: res.data?.prsSearchError ? 'PRS match error' : !res.data?.workTunecode ? 'PRS not mached': (res.data?.workTunecode && res.data?.update) ? 'PRS mached' : 'PRS match error.',
      });

      setTimeout(()=>{
        dispatch({
          type: actionTypes.POST_OK,
        });
      }, 2000);

      if (done) done();
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POSTING_ERROR,
        payload: `Error matching with PRS. please retry`,
      });

      setTimeout(()=>{
        dispatch({
          type: actionTypes.POST_ERROR,
          payload: err.response?.data || { e: (err.message ? err.message : "Error matching with PRS. please retry") },
        });
      }, 2000);

      if (done) done();
    });
};

export const clearErrors = (done) => (dispatch) => {
  dispatch({
    type: actionTypes.CLEAR_ERRORS,
    payload: {},
  });
  if (done) done();
};

