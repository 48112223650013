import React, { Component } from "react";
import { IconButton, MenuItem, Menu, Button } from "@material-ui/core";
import { Translation } from "react-i18next";
import { PlaylistGrants } from "./Columns";
import Avatar from "@material-ui/core/Avatar";
import { _PermittedContent } from "../../../_module/utils/PermittedContent";
import {
  _mlPlaylists,
  _mlPlaylistsShare,
  _mlPlaylistsDownload,
  _mlPlaylistsShareFull,
} from "../../../_module/definition/MlPlaylists";

import {
  _playouts
} from "../../../_module/definition/playout";

import { _mlPlPromoAdmin } from "./../../../_module/definition/MlPlaylists";
import store from "redux/Store";
import { userDisplay } from "util/CommonFunc";
import clsx from "clsx";
import SettingsIcon from '@material-ui/icons/Settings';
import { BiTrash } from "react-icons/bi";
import PlaylistSharePermission from "./PlaylistSharePermission";
import {
  recipientGroupType,
} from "./../../../redux/Types";
import Confirm from "util/Confirm";
import { appConfig } from "App";

export const isPromoAdmin = () => {
  return _PermittedContent([_mlPlaylists, _mlPlPromoAdmin]) ? true : false;
};

export const canSharePlaylist = () => {
  return _PermittedContent([_mlPlaylists, _mlPlaylistsShare]) || _PermittedContent([_mlPlaylists, _mlPlaylistsShareFull]) ? true : false;
};
export const canSharePlaylistUpdated = (currentUserPerm = null) => {
  if (!currentUserPerm) {
    return _PermittedContent([_mlPlaylists, _mlPlaylistsShare]) || _PermittedContent([_mlPlaylists, _mlPlaylistsShareFull]) ? true : false;
  }
  else {
    if (currentUserPerm.plContentSharePlaylistShare?.v || currentUserPerm.plContentSharePlaylistShareFull?.v) {
      return true
    }
    else {
      return false
    }
  }

};

export const canDownloadPlaylist = () => {
  return _PermittedContent([_mlPlaylists, _mlPlaylistsDownload]) ? true : false;
};

export const getPlUserPermissionDownload = (currentUser, plUsers, currentOrg) => {
  let index = null;
  if (plUsers && plUsers.length > 0) {
    // index = plUsers.findIndex(e => (e.email == currentUser && e.orgId == currentOrg))
    index = plUsers.findIndex(e => (e.email == currentUser && e.orgId == appConfig.org?.code))
  }

  if (index == -1 || index == null) {
    // console.log("no index",index,plUsers,plUsers[index])
    return false
  }
  else {
    let currentUserPerm = plUsers[index].permission?.content;
    if (currentUserPerm == null) {
      // console.log("default perm null",index)
      return null
    }
    else if(currentUserPerm.canDownloadHighres?.v == "none" && currentUserPerm.canDownloadMp3?.v == "none"){
      return false
    }
    else if (currentUserPerm.canDownloadHighres?.v != "none" || currentUserPerm.canDownloadMp3?.v != "none") {
      // console.log("current perm canDownloadHighres/canDownloadMp3",currentUserPerm)
      return true
    }
  }
};

export const getCurrentUserPermission = (currentUser, plUsers, currentOrg) => {
  let index = null;
  if (plUsers && plUsers.length > 0) {
    // index = plUsers.findIndex(e => (e.email == currentUser && e.orgId == currentOrg))
    index = plUsers.findIndex(e => (e.email == currentUser && e.orgId == appConfig.org?.code))
  }

  if (index == -1 || index == null) {
    return null
  }
  else {
    return plUsers[index].permission?.playlist
  }
};

export const canSendToPlayout = () => {
  return _PermittedContent([_playouts])
    ? true
    : false;
};

const getUserGroups = (user) => {
  if (
    user &&
    user.credentials &&
    user.credentials.orgID &&
    user.credentials.orgs &&
    user.credentials.orgs.length
  ) {
    const org = user.credentials.orgs.find(
      (r) => r.id === user.credentials.orgID
    );
    if (org && org.groups && org.groups.length) {
      return org.groups;
    }
  }
  return null;
};

const isUserMatch = (user, playlist, grants) => {
  return playlist?.users?.some(
    (r) =>
      r.id === user.id && grants?.some((n) => n === r.grant)
  );
};

const isGroupMatch = (
  user,
  playlist,
  grants
) => {
  const userGroups = getUserGroups(user);

  if (userGroups && userGroups.length) {
    return playlist?.groups?.some(
      (r) =>
        userGroups.some((g) => g === r.id) && grants?.some((n) => n === r.grant)
    );
  }

  return null;
};

export const isPlaylistEditable = (user, playlist) => {
  // return true // commented temp
  return playlist &&
    (!playlist.id ||
      playlist?.createdBy === user.id)
    ? true
    : false;
};

export const isPlaylistDeletable = (user, playlist) => {
  return playlist &&
    (!playlist.id ||
      playlist?.createdBy === user.id ||
      isUserMatch(user, playlist, [PlaylistGrants.ReadWriteDelete]) ||
      isGroupMatch(user, playlist, [PlaylistGrants.ReadWriteDelete]))
    ? true
    : false;
};

export const getPermission = (rolePermission, playlistPermission, playlist, orgId, user, email)=> {
  let userLevelPermission = getUserLevelPermission(rolePermission, playlistPermission, playlist, orgId, email);
  
  return userLevelPermission

}

export const getUserLevelPermission = (rolePermission, playlistPermission, playlist, orgId, email) =>{
  //Find user in shared users
  let sharedUser = playlist.users?.find(u => u.email == email && u.orgId == orgId);
  if (!sharedUser?.permission[playlistPermission[0]]) {
    if (!rolePermission || rolePermission.length == 0) {
      // console.log("default role perm !rolePermission");
      return [];
    }
    // console.log("default role perm",rolePermission,_PermittedContent(rolePermission));
    return _PermittedContent(rolePermission) ? true : false;


  }
  else {
    // console.log("custom perm");
    return readPermissionValue(playlistPermission, sharedUser.permission);
  }
}

export const readPermissionValue = (permission, permissionObject) => {


  let selectedValue = permissionObject;
  for (let param of permission) {
    if (!selectedValue) {
      break;
    }
    selectedValue = selectedValue[param];
  }
  if (selectedValue && selectedValue.v) {
    return selectedValue.v;
  }
  else {
    return null;
  }
}

export default class SharedUserSettings extends Component {
  constructor(props) {
    super();
    this.state = {
      busy: false,
      permissionShareOpen: false,
      removeUserConfirmOpen: false,
      userAction: "",
      removeUser: null
    };
  }

  hanldeOpenPermissionShareModule = () => {
    this.setState({ permissionShareOpen: true });
  }

  handleClosePermissionShareModule = () => {
    this.setState({ permissionShareOpen: false })
  }

  handleSelectedUserRemove = (id) => {
    var selectedUsers = this.state.selectedUsers.filter((r) => r.id !== id);
    this.setState({ selectedUsers: selectedUsers });
  };

  handleMenuClick = (event) => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = (event) => {
    event.stopPropagation();
    this.setState({ anchorEl: null });
  };

  handleSharedUserAction = (event, action, user) => {
    event.stopPropagation();
    this.setState({ anchorEl: null });

    const { addUsers, removeUsers } = this.props;

    if (action === "canedit") {
      addUsers([{ id: user.id, grant: PlaylistGrants.ReadWrite }]);
    } else if (action === "candelete") {
      addUsers([{ id: user.id, grant: PlaylistGrants.ReadWriteDelete }]);
    } else if (action === "readonly") {
      addUsers([{ id: user.id, grant: PlaylistGrants.ReadOnly }]);
    } else if (action === "remove") {
      removeUsers([{ id: user.id, email: user.email, orgId: user.orgId }]);
    }
  };

  handleEditUserPerm = (user) => {
    const { addUsers } = this.props;
    this.setState({ permissionShareOpen: false });
    addUsers(user);
  }

  getUserObj = (user) => {
    let newUser = {};
    if (user) {
      newUser.id = user.orgUser?.userId;
      newUser.grant = user.sharedSettings?.grant;
      newUser.hasPermission = user.orgUser?.hasPermission;
      newUser.imageUrl = user.orgUser?.imageUrl;
      newUser.isExternal = user.orgUser?.isExternal;
      newUser.name = user.orgUser?.name;
      newUser.orgId = user.orgUser?.orgId;
      newUser.orgName = user.orgUser?.orgName;
      newUser.orgUserStatus = user.orgUser?.orgUserStatus;
      newUser.userStatus = user.orgUser?.userStatus;
      newUser.email = user.orgUser?.email;
      newUser.permission = user.sharedSettings?.permission;

    }
    return newUser
  }

  getGroupObj = (user) => {
    let newUser = {};
    if (user) {
      newUser.id = user.orgUser?.id;
      newUser.grant = user.sharedSettings?.grant;
      newUser.imageUrl = user.orgUser?.imageUrl;
      newUser.name = user.orgUser?.name;
      newUser.orgId = user.orgUser?.orgId;
      newUser.email = user.orgUser?.email;
      newUser.users = user.orgUser?.users;
      newUser.permission = user.sharedSettings?.permission;

    }
    return newUser
  }

  handleRemoveConfirmOpen = (userAction, removeUser) => {
    this.setState({ removeUserConfirmOpen: true, userAction: userAction, removeUser: removeUser })
  }

  hanldeRemoveConfirm = (e) => {
    this.handleSharedUserAction(e, this.state.userAction, this.state.removeUser);
    this.handleRemoveUserConfClose();
  }

  handleRemoveUserConfClose = () => {
    this.setState({ removeUserConfirmOpen: false, userAction: "", removeUser: null })
  }

  render() {
    const { classes, user, getGrantType, playlist, showMoreSettings, groupingType } = this.props;
    const { anchorEl, busy, permissionShareOpen } = this.state;
    const { user: loggedUser } = store.getState();

    return (
      <Translation>
        {(t, { i18n }) => (
          <>
            <Confirm
              name="shareUserRemoveConfirmdDialog"
              classes
              isOpen={this.state.removeUserConfirmOpen}
              title="Confirmation Alert"
              content="Are you sure, Do you want to remove the user?"
              confirm="Yes"
              cancel="No"
              onConfirm={(e) => this.hanldeRemoveConfirm(e)}
              onCancel={this.handleRemoveUserConfClose}
            />
            {permissionShareOpen && (
              <PlaylistSharePermission
                open={permissionShareOpen}
                close={this.handleClosePermissionShareModule}
                classes={classes}
                DialogBoxTitle={`${playlist?.name} - Set Permission`}
                addPermission={(users, groups) => {
                  this.handleEditUserPerm(users, groups)
                }}
                users={groupingType == recipientGroupType.GROUP ? [this.getGroupObj(user)] : [this.getUserObj(user)]}
              // groups={this.state.selectedGroups}
              // addNewUsers={true}
              />
            )}
            <div className={classes.sharedUserList}>
              <Avatar
                alt={user.orgUser?.name}
                src={user.orgUser?.imageUrl}
                className={classes.smallAvatar}
              ></Avatar>
              <div className={clsx(classes.sharedUserListSingleWidth, classes.sharedUserListSingle, user.orgUser?.isExternal && !user.orgUser?.hasPermission ? classes.extUser : null)}>
                <div title={user.orgUser?.name + " " + (user.orgUser?.bbcCompany || "") + " " + (user.orgUser?.bbcDepartment || "")} className={classes.sharedUserListName}>
                  {user.orgUser?.name + " " + (user.orgUser?.bbcCompany || "") + " " + (user.orgUser?.bbcDepartment || "")}
                  <div title={user.orgUser?.email} className={classes.sharedUserListEmail}>{user.orgUser?.email}</div>
                </div>
                {/* {user.orgUser?.isExternal &&
                  <div title={user.orgUser?.orgName} className={clsx(classes.sharedUserListEmail, classes.sharedOrg)}>
                    {user.orgUser?.orgName}
                  </div>} */}
              </div>
              {/* <div className={classes.sharedUserEmail}>
                {getGrantType(user.sharedSettings.grant)}
              </div> */}
              {showMoreSettings &&
                <div className={classes.buttonSetforShare}>
                  <IconButton
                    className={""}
                    disabled={busy}
                    onClick={(e) => {
                      // this.handleSharedUserAction(
                      //   e,
                      //   "remove",
                      //   user.sharedSettings
                      // );
                      this.handleRemoveConfirmOpen("remove", user.sharedSettings)
                    }}
                    aria-label={"Remove User"}
                    title={"Remove User"}
                  >
                    <BiTrash />
                  </IconButton>
                  <IconButton
                    title={"Open permission settings"}
                    onClick={() => this.hanldeOpenPermissionShareModule()}
                  >
                    <SettingsIcon />
                  </IconButton>
                  {/* <IconButton
                    aria-controls="action-menu"
                    aria-label={`More actions menu for ${user.orgUser?.name}`}
                    aria-haspopup="true"
                    onClick={this.handleMenuClick}
                    title={`More actions menu for ${user.orgUser?.name}`}
                    disabled={playlist.createdBy != loggedUser.id}
                  >
                    <MoreHorizIcon />
                  </IconButton> */}
                  {/* <Menu
                    keepMounted
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      horizontal: "left",
                      vertical: "bottom",
                    }}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleMenuClose}
                  >
                    <MenuItem
                      disabled={busy}
                      onClick={(e) => {
                        this.handleSharedUserAction(
                          e,
                          "canedit",
                          user.sharedSettings?.id
                        );
                      }}
                    >
                      {t("playlistTools.canEdit")}
                    </MenuItem>
                    <MenuItem
                      disabled={busy}
                      onClick={(e) => {
                        this.handleSharedUserAction(
                          e,
                          "candelete",
                          user.sharedSettings?.id
                        );
                      }}
                    >
                      {t("playlistTools.canDelete")}
                    </MenuItem>
                    <MenuItem
                      disabled={busy}
                      onClick={(e) => {
                        this.handleSharedUserAction(
                          e,
                          "readonly",
                          user.sharedSettings?.id
                        );
                      }}
                    >
                      {t("playlistTools.readOnly")}
                    </MenuItem>
                    <MenuItem
                      disabled={busy}
                      onClick={(e) => {
                        this.handleSharedUserAction(
                          e,
                          "remove",
                          user.sharedSettings?.id
                        );
                      }}
                    >
                      {t("playlistTools.remove")}
                    </MenuItem>
                  </Menu> */}
                </div>
              }
            </div>
          </>
        )}
      </Translation>
    );
  }
}
