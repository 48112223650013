import axios from "axios";
import store from "redux/Store";
import { pagingTotalRelation } from "redux/Types";
import { v4 as uuidv4 } from "uuid";
import { helpType } from "../help-view/Const";
import { ValidateHelp } from "../validation/helpActionValidation";
module = "/help";

// const AWS = require('aws-sdk');

export const actionTypes = {
  EDIT_ITEM: "EDIT_ITEM" + module,
  POSTING: "POSTING" + module,
  POST_OK: "POSTOK" + module,
  POST_ERROR: "POST_ERROR" + module,
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  LOADING_CONTENT: "LOADING_CONTENT" + module,
  LOAD_CONTENT: "LOAD_CONTENT" + module,
  LOAD_SEARCH_CONTENT: "LOAD_SEARCH_CONTENT" + module,
  ERROR: "ERROR" + module,
  ERROR_CONTENT: "ERROR_CONTENT" + module,
  QUERY: "QUERY" + module,
  CONTENT_QUERY: "CONTENT_QUERY" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  FILTER_SEARCH: "FILTER_SEARCH" + module,
  DRAWER_OPEN: "DRAWER_OPEN" + module,
  ALL_ITEMS: "ALL_ITEMS" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  CLEAR_ERRORS: "CLEAR_ERRORS" + module,

  LOADING_PRIVATE_VIDEOS: "LOADING_PRIVATE_VIDEOS" + module,
  LOAD_PRIVATE_VIDEOS: "LOAD_PRIVATE_VIDEOS" + module,
  ERROR_PRIVATE_VIDEOS: "ERROR_PRIVATE_VIDEOS" + module,
};

export const setDrawerOpen = (status, config , done = null) => (dispatch) => {
    dispatch({
      type: actionTypes.DRAWER_OPEN,
      payload: {drawerOpen : status, config : config},
    });

    if (done) done();
  };


export const setQuery = (query , done = null) => (dispatch) => {
    dispatch({
      type: actionTypes.QUERY,
      payload: query,
    });

    if (done) done();
  };

  export const setContentQuery = (query , done = null) => (dispatch) => {
    dispatch({
      type: actionTypes.CONTENT_QUERY,
      payload: query,
    });

    if (done) done();
  };

export const filterSearch = (config, items, done = null) => (dispatch) => {
    dispatch({
      type: actionTypes.FILTER_SEARCH,
      payload: {[config.id] : items},
    });

    if (done) done();
  };

export const search = (query) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  
  const tId = uuidv4();
  dispatch({
    type: actionTypes.LOADING,
  });
  
  axios
    .post(`${orgID}/mladmin/help/search`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.LOAD,
          payload: res.data.results,
          total: res.data.total,
          totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
    });
};

export const getHelpData = (config, category = "", title="") => (dispatch) => {

  const {
    user: {
      credentials: { orgID },
    },
    help,
  } = store.getState();

    const tId = uuidv4();
  dispatch({
    type: actionTypes.LOADING_CONTENT,
    loading : {[config.id] : true}
  });

  if(!category && !title && config?.id in help?.allItems){
    return dispatch({
      type: actionTypes.LOAD_CONTENT,
      payload: {[config.id] : help.allItems[config.id]},
      loading : {[config.id] : false}
    });
  }

  if(category === helpType.ALL) category = '';
  
  axios
    .post(`${orgID}/mladmin/help/getData`, {id: config.id, category : category, title : title}, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {

      if(category || title){
        dispatch({
          type: actionTypes.LOAD_SEARCH_CONTENT,
          payload: {[config.id] : res.data},
          loading : {[config.id] : false}
        });
      }else{
        // if (res.data.tid === tId) {
          dispatch({
            type: actionTypes.LOAD_CONTENT,
            payload: {[config.id] : res.data},
            loading : {[config.id] : false}
          });
        // }
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR_CONTENT,
        payload: { e: err },
        loading : {[config.id] : false}
      });
    });
};

export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });
};

export const editItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.EDIT_ITEM,
    payload: item,
  });
};


export const postItem = (action, message, items, done = null) => (dispatch) => {

  // ValidateHelp
  if(items.help){
    let errors = ValidateHelp(items.help);
    if (errors) {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: errors
      });
      return;
    }
  }

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/mladmin/help/${action}`, items, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      // if (res.data.tid === tId) {
      dispatch({
        type: actionTypes.POST_OK,
        payload: `${action == "savehelp"
                    ? 'Save Help'
                    : action == "archivehelp"
                    ? 'Archive Help'
                    : action == "restorehelp"
                    ? 'Restore Help'
                    : action } successful`,
      });
      if (done) done(res.data, null);
      // }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: err.response?.data || { e: (err.message ? err.message : "Error in creating or updating. please retry") },
      });
      if (done) done(null, err);
    });
};

export const selectItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};

export const clearErrors = (done) => (dispatch) => {
  dispatch({
    type: actionTypes.CLEAR_ERRORS,
    payload: {},
  });
  if (done) done();
};

export const getPrivateVideos = () => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  
  const tId = uuidv4();
  dispatch({
    type: actionTypes.LOADING_PRIVATE_VIDEOS,
  });
  
  axios
    .get(`${orgID}/mladmin/help/privateVideoList`)
    .then((res) => {
        dispatch({
          type: actionTypes.LOAD_PRIVATE_VIDEOS,
          payload: res.data,
        });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR_PRIVATE_VIDEOS,
        payload: { e: err },
      });
    });
};

