import React, { Component } from "react";
import { Translation } from "react-i18next";
import { _getModuleDef, _newModuleInstance } from "_module/utils/ModuleUtils";
import {
  withStyles,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Button,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { styles } from "./ModuleEdit.css";

class ModuleEditOptionsMultiSelect extends Component {
  moduleMenuOpned = false;
  constructor(props) {
    super();
    this.state = {
      anchorEl: null
    };
  }

  handleClick = (event) => this.setState({ anchorEl: event.currentTarget });
  handleClose = () => {
    this.setState({ anchorEl: null });
    this.moduleMenuOpned = false;
  }

  handleToggle = (id) => {
  
    var { selected, handleChange} = this.props;
    const currentIndex = selected.findIndex((v) => {    
      return v === id;
    });

    const newChecked = [...selected];
    const value = id;

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    handleChange(newChecked);
  };

  sortAlpha(a, b){
    let x = a;
    let y = b;
    if (x < y) {return -1;}
    if (x > y) {return 1;}
    return 0;
  }

  render() {
    let excludeKeys = ['session_id'];
    var { selected,options } = this.props;
    const { anchorEl} = this.state;
    return (
      <Translation>
        {(t, { i18n }) => (
          <>
            <Button
              aria-haspopup="true"
              onClick={this.handleClick}
            >
              +
            </Button>
            <Menu
              keepMounted
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              getContentAnchorEl={null}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
            >
              {Object.keys(options).filter(d=>excludeKeys.indexOf(d) == -1).sort(this.sortAlpha).map((id, index) => (
                <MenuItem
                  onKeyUp={(e)=>{
                    if(e.key === 'Enter' && this.moduleMenuOpned){
                      this.handleToggle(id);
                    }
                    else{
                      this.moduleMenuOpned = true;
                    }
                  }}
                >
                <ListItem button onClick={() => this.handleToggle(id)} key={id}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={
                        selected.findIndex((v) => {
                          return v.name ? v.id === id : v === id;
                        }) !== -1
                      }
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        'aria-label': `Checkbox on/off ${options[id].name || options[id].label || t(`modules.${options[id].id}`)}`
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={options[id].id}
                    primary={options[id].name || options[id].label || t(`modules.${options[id].id}`)}
                  />
                </ListItem>
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </Translation>
    );
  }
}


export default withStyles(styles)(ModuleEditOptionsMultiSelect);
