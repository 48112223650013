import React from "react";
import {
  ColumnStickyTypes,
  ColumnTypes,
  JustificationTypes,
} from "../../../util/table-view/TableTypes";
import { Link } from "react-router-dom";
import i18n from "i18next";
import {  PageTableType } from "./../../../redux/Types";
import HighlightedText from "../../../util/HighlightedText";
import PlayerRef from "../../ml-player/PlayerRef/PlayerRef";
import { PlayableTypes } from './../../ml-player/PlayerRef/PlayerRef';
import { ChartIndicator } from "./ChartIndicator";
import { userDisplay } from "../../../util/CommonFunc";

export const PlaylistStatusTypes = {
  active: 1,
  archived: 2,
};

export const PlaylistTypes = {
  public: 1,
  private: 2,
};

export const PlaylistGrants = {
  ReadOnly: "r",
  ReadWrite: "w",
  ReadWriteDelete: "d",
};

export const ChartIndicators = {
  Chart: "c",
  None: "x",
};

export const PlaylistShareObjPermissions = {
  PLAYLIST: "playlist",
  CONTENT: "content",
};

export const DecryptGrantType = (code) => {
  switch (code) {
    case PlaylistGrants.ReadOnly:
      return i18n.t("playlistTools.readOnly");
    case PlaylistGrants.ReadWrite:
      return i18n.t("playlistTools.canEdit");
    case PlaylistGrants.ReadWriteDelete:
      return i18n.t("playlistTools.canDelete");
    default:
      return code;
  }
};

export default [
  {
    field: "selector",
    width: 30,
    type: ColumnTypes.selector,
    sticky: ColumnStickyTypes.left,
  },
  {
    field: "chartType",
    label: "⭐",
    width: 30,
    isSortable: true,
    alignHeader: JustificationTypes.left,
    alignCell: JustificationTypes.left,
    type: ColumnTypes.elasticString,
    cellProp : (rowData)=>{
      return (
        {artworkProps: {
          title : `Chart Indicator`,
        },},
        {accessDataProps: {
          title : `Chart Indicator`,
        },}
      )
    },
    cellRenderer: (rowData, props)=> (<div class="ColChartIndicator"><ChartIndicator playlist={rowData}/></div>)
  },
  {
    field: "player",
    width: 20,
    alignHeader: JustificationTypes.center,
    alignCell: JustificationTypes.center,
    type: ColumnTypes.playerAndThumb,
    tablePageType: PageTableType.MYPLAYLIST,
    artworkField: 'artwork',
    cellProp : (rowData)=>{
      return (
        {artworkProps: {
          alt : `Art work of playlist ${rowData.name}`,
        },}
      )
    },
    cellRenderer: (rowData) => (<PlayerRef plId={rowData.id} key={rowData.id} ariaLabel={`Play playlist ${rowData.name}`} item={rowData} playableType={PlayableTypes.playlist} popoutFor={PageTableType.MYPLAYLIST_TRACKS} />)
  },
  {
    field: "name",
    label: "Title",
    width: 140,
    type: ColumnTypes.string,
    isSortable: true,
    isFilterable: true,
    cellRenderer: (rowData) => (
      <Link
        style={{ textDecoration: "none", color: "#fff" }}
        key={rowData.id}
        to={`/playlist/${rowData.id}`}
        aria-label={`Title of the playlist ${rowData.name}`}
      >
        {/* <span style={{float: "right"}}>{rowData.itemsCount ? `(${rowData.itemsCount})` : null}</span> */}
       <strong class="tablesLink">
       <h3 class="trackAccTitle">  {HighlightedText(rowData, "name")}</h3>
        </strong>
        
      </Link>
    ),
  },
  {
    field: "categoryId",
    label: "Category",
    width: 100,
    type: ColumnTypes.options,
    isSortable: true,
    isFilterable: true,
  },
  {
    field: "releaseDate",
    label: "Release Date",
    width: 240,
    isSortable: true,
    isFilterable: true,
    type: ColumnTypes.date,
  },
  {
    field: "status",
    label: "Status",
    width: 120,
    type: ColumnTypes.options,
    isSortable: true,
    isFilterable: true,
    filterOptions: [
      { id: PlaylistStatusTypes.active, label: "ACTIVE" },
      { id: PlaylistStatusTypes.archived, label: "ARCHIVED" },
    ],
  },
  {
    field: "tags",
    label: "Tags",
    width: 100,
    type: ColumnTypes.tag,
    isSortable: true,
    isFilterable: true,
    cellRenderer: (rowData) => rowData.tags && rowData.tags.length > 0 ? rowData.tags.join(", ") : rowData.tags
  },
  {
    field: "notes",
    label: "Notes",
    width: 100,
    type: ColumnTypes.string,
    isSortable: true,
    isFilterable: true,
  },
  {
    field: "type",
    label: "Type",
    width: 150,
    type: ColumnTypes.options,
    isSortable: true,
    isFilterable: true,
    filterOptions: [
      { id: PlaylistTypes.private, label: "Standard" },
      { id: PlaylistTypes.public, label: "Promoted" },
    ],
    cellProp : (rowData)=>{
      return (
        {accessDataProps: {
          ariaLabel : "Type",
        },}
      )
    },
  },
  {
    field: "itemsCount",
    label: "No. of Items",
    width: 200,
    alignCell: JustificationTypes.right,
    type: ColumnTypes.number,
    isSortable: true,
    isFilterable: true,
    cellProp : (rowData)=>{
      return (
        {accessHeaderProps: {
          ariaLabel : "Number of Items",
        },}
      )
    },
  },
  {
    field: "priority",
    label: "Priority",
    width: 160,
    alignCell: JustificationTypes.right,
    type: ColumnTypes.number,
    isSortable: true,
    isFilterable: true,
  },
  {
    field: "createdAt",
    label: "Created",
    width: 240,
    isSortable: true,
    isFilterable: true,
    type: ColumnTypes.datetime,
  },
  {
    field: "createdBy",
    label: "Created By",
    width: 120,
    isSortable: true,
    isFilterable: true,
    type: ColumnTypes.users,
    cellProp : (rowData)=>{
      return (
        {accessDataProps: {
          title : `${userDisplay(rowData?.createdBy)}`,
        },}
      )
    },
    cellRenderer: (rowData) => (<span title={userDisplay(rowData.createdBy)}>{userDisplay(rowData.createdBy)}</span>)
  },
  {
    field: "updatedAt",
    label: "Updated",
    width: 240,
    isSortable: true,
    isFilterable: true,
    type: ColumnTypes.datetime,
  },
  {
    field: "updatedBy",
    label: "Updated By",
    width: 100,
    isSortable: true,
    isFilterable: true,
    type: ColumnTypes.users,
    cellProp : (rowData)=>{
      return (
        {accessDataProps: {
          title : `${userDisplay(rowData?.updatedBy)}`,
        },}
      )
    },
    cellRenderer: (rowData) => (<span title={userDisplay(rowData.updatedBy)}>{userDisplay(rowData.updatedBy)}</span>)
  },
  {
    field: "menu",
    width: 30,
    type: ColumnTypes.menu,
    sticky: ColumnStickyTypes.right,
    tablePageType: PageTableType.MYPLAYLIST,
  },
];
