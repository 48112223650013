import { actionTypes } from "./PrsSearchButtonActions";


const initialState = {
  posting: { busy: false, message: "", errors: null },
  loading: false,
  errors: null,
};

export default function (state = initialState, action) {
  switch (action.type) {

    case actionTypes.POSTING:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.POSTING_OK:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.POST_OK:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: null,
        },
      };

    case actionTypes.POSTING_ERROR:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.POST_ERROR:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: action.payload,
        },
      };

    case actionTypes.CLEAR_ERRORS:
      return {
        ...state,
        posting: {
          errors: null,
        },
      };

    default:
      return state;
  }
}
