import axios from "axios";
import store from "redux/Store";
import { trimSearchQuery } from "util/CommonFunc";
import { v4 as uuidv4 } from "uuid";
import { validateContentAlert } from "./ContentAlertValidation";

module = "/contentAlert";

export const actionTypes = {

  NEW_COUNT: "NEW_COUNT" + module,
  NEW_UNREAD_COUNT: "NEW_UNREAD_COUNT" + module,
  EDIT_ITEM: "EDIT_ITEM" + module,
  EDIT_ITEMS_SAVECB : "EDIT_ITEMS_SAVECB" + module,
  CLOSE_MESSAGECB : "CLOSE_MESSAGECB" + module,
  UPDATE_CONTENTREQ_STATUS : "UPDATE_CONTENTREQ_STATUS" + module,

  POSTING: "POSTING" + module,
  POST_OK: "POSTOK" + module,
  POSTING_ERROR: "POSTING_ERROR" + module,
  POST_ERROR: "POST_ERROR" + module,

  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,

  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  QUERY: "QUERY" + module,

  REFRESH_ITEMS: "REFRESH_ITEMS" + module,
  PREVIEW_TAB: "PREVIEW_TAB" + module,

};

export const refreshItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.REFRESH_ITEMS,
    payload: items,
  });
};


export const setQuery = (query, done = null) => (dispatch) => {

    dispatch({
      type: actionTypes.QUERY,
      payload: query,
    });
  
    if (done) done();
  };
  
  export const selectItems = (items) => (dispatch) => {
    dispatch({
      type: actionTypes.SELECT_ITEMS,
      payload: items,
    });
  };
  
  export const focusItem = (item) => (dispatch) => {
    dispatch({
      type: actionTypes.FOCUS_ITEM,
      payload: item,
    });
  };

export const search = (query) => (dispatch) => {
    const {
      user: {
        credentials: { orgID },
      },
    } = store.getState();
  
    const tId = uuidv4();
    dispatch({
      type: actionTypes.LOADING,
    });
  
    axios
      .post(`${orgID}/contentAlert/search`, trimSearchQuery(query), {
        headers: { tid: tId, orgid: orgID },
      })
      .then((res) => {
        if (res.data.tid === tId) {
          dispatch({
            type: actionTypes.LOAD,
            payload: res.data.results,
            total: res.data.total,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ERROR,
          payload: { e: err },
        });
      });
  };


  export const postItem = (action, message, item, done = null) => (dispatch) => {
  

    const {
      user: {
        credentials: { orgID },
      },
    } = store.getState();
    const tId = uuidv4();
  
    dispatch({
      type: actionTypes.POSTING,
      payload: message,
    });
  
    axios
      .post(`${orgID}/contentAlert/${action}`, item, {
        headers: { tid: tId, orgid: orgID },
      })
      .then((res) => {
        //if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.POST_OK,
        });
  
        if (done) done(res.data, null);
        //}
      })
      .catch((err) => {

        dispatch({
          type: actionTypes.POSTING_ERROR,
          payload: `Error ${action === "save" ? "creating" : "updating"} content alert. please retry`,
        });

        setTimeout(()=>{
          dispatch({
            type: actionTypes.POST_ERROR,
            // payload: { e: err },
            payload: err.response?.data || {
              e: err.message
                ? err.message
                : `Error ${action === "save" ? "creating" : "updating"} content alert. please retry`,
            },
          });
        }, 2000);
  
        if (done) done(null, err);
      });
  };

  export const validateCA = (data, done = null) => (dispatch) => {
    if (data) {
      let errors = validateContentAlert(data);
      if (done) done(errors);
    }
  };

  export const setFocusPreview = (data) => (dispatch) => {
    dispatch({
      type: actionTypes.PREVIEW_TAB,
      payload: data,
    });
  };
