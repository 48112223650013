import axios from "axios";
import store from "redux/Store";
import { v4 as uuidv4 } from "uuid";
import { ValidateAddUser } from "../../util/validations/MlAdminUserActionsValidation";
import { fs } from "../../data-services/firebase";
import { pagingTotalRelation } from "redux/Types";
module = "/mlAdmin/user/";
export const actionTypes = {
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  CLEAR_PROFILE_IMAGE: "CLEAR_PROFILE_IMAGE" + module,
  UPDATE_PROFILE_IMAGE: "UPDATE_PROFILE_IMAGE" + module,
  QUERY: "QUERY" + module,
  EDIT_ITEM: "EDIT_ITEM" + module,
  POSTING: "POSTING" + module,
  POST_OK: "POSTOK" + module,
  POST_ERROR: "POST_ERROR" + module,
  LOAD_HISTORY: "LOAD_HISTORY" + module,
  REMOVE_HISTORY: "REMOVE_HISTORY" + module,
};

var tId = null;

export const editItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.EDIT_ITEM,
    payload: item,
  });
};

export const postItem = (action, message, items, done = null) => (dispatch) => {
  if (items.user) {
    let errors = ValidateAddUser(items.user);
    if (errors) {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: errors
      });
      return;
    }
  }
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/mladmin/users/${action}`, items, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      //if (res.data.tid === tId) {
      dispatch({
        type: actionTypes.POST_OK,
        payload: `${action == "archiveuser"? 'Archive user': action == "inviteuser"? 'Invite user': action == "restoreuser"? 'Restore user': action == "removeabuseflag"? 'Remove abuse flag': action } successful`,
      });
      if (done) done(res.data, null);
      //}
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: err.response?.data || { e: (err.message ? err.message : "Error in creating or updating. please retry") },
      });
      if (done) done(null, err);
    });
};

export const inviteQueriedUser = (items, query, message = "", done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/mladmin/users/invitequeriedeuser`, { items: items, query:query }, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      let count = 0;
      let inviteQueriedSubscription = fs.collection('csv_export_response')
        .doc(res.data)
        .onSnapshot(
          (docSnapshot) => {
            if(docSnapshot.data()){
              let doc = docSnapshot.data();
                if(doc.complete){
                  dispatch({
                    type: actionTypes.POSTING,
                    payload: doc?.message
                  });
                  setTimeout(()=>{
                    dispatch({
                      type: actionTypes.POST_OK,
                      payload: ""
                    });
                  }, 2000);
                  inviteQueriedSubscription();
                }
                else{
                  if(doc.success){
                    count += 1;
                    dispatch({
                      type: actionTypes.POSTING,
                      payload: doc?.message?`${doc.message} (${count}/${doc.total})`:""
                    });
                  }
                  else{
                    dispatch({
                      type: actionTypes.POSTING,
                      payload: doc?.message
                    });
                  }
                }
              }
            },
            (err) => {
              console.log(`[subscribe] Encountered error: ${err}`);
            }
        );

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: err.response?.data || { e: (err.message ? err.message : "Error in inviting queried users. please retry") },
      });

      if (done) done(null, "Error in inviting queried users");
    });
};
export const importUsers = (usersList, message = "", done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/mladmin/users/importusers`, usersList, {
      headers: { tid: tId, orgid: orgID, "Content-Type":'multipart/form-data' },
    })
    .then((res) => {
      let inviteQueriedSubscription = fs.collection('csv_export_response')
      .doc(res.data)
      .onSnapshot(
        (docSnapshot) => {
          if(docSnapshot.data()){
            let doc = docSnapshot.data();
              if(doc.complete){
                dispatch({
                  type: actionTypes.POSTING,
                  payload: doc?.message
                });
                setTimeout(()=>{
                  dispatch({
                    type: actionTypes.POST_OK,
                    payload: ""
                  });
                }, 2000);
                inviteQueriedSubscription();
              }
              else{
                if(doc.success){
                  dispatch({
                    type: actionTypes.POSTING,
                    payload: doc?.message?`${doc.message}`:""
                  });
                }
                else{
                  dispatch({
                    type: actionTypes.POSTING,
                    payload: doc?.message
                  });
                }
              }
            }
          },
          (err) => {
            console.log(`[subscribe] Encountered error: ${err}`);
          }
      );

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: err.response?.data || { e: (err.message ? err.message : "Error in importing users. please retry") },
      });

      if (done) done(null, "Error in importing users");
    });
};

export const setQuery = (query, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.QUERY,
    payload: query,
  });

  if (done) done();
};

export const selectItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};

export const clearProfileImage = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.CLEAR_PROFILE_IMAGE,
    payload: item,
  });
};

export const clearMessage = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.POST_OK,
    payload: item,
  });
};

export const setMessage = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.POSTING,
    payload: item,
  });
};

export const updateProfileImage = (imageUrl) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_PROFILE_IMAGE,
    payload: imageUrl,
  });
};

export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });
};
export const search = (query) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  dispatch({
    type: actionTypes.LOADING,
  });

  tId = uuidv4();

  axios
    .post(`${orgID}/mladmin/users/search`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.LOAD,
          payload: res.data.results,
          total: res.data.total,
          totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
    });
};

export const getUserHistory = (userId, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  axios
    .post(`${orgID}/mladmin/users/searchHistory`, { userId: userId }, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      
      dispatch({
        type: actionTypes.LOAD_HISTORY,
        payload: {
          userId:userId,
          history:res.data
        }
      });

      if (done) done(res.data, null);
      
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: err.response?.data || { e: (err.message ? err.message : "Error in searching user history. please retry") },
      });

    });
};

export const removeHistory = (ids) => (dispatch) => {
  // Use timeout to give Elastic time to refresh the last logged activity
  setTimeout(function () {
    dispatch({
      type: actionTypes.REMOVE_HISTORY,
      payload: ids,
    });
  }, 500);
};

export const checkExistingSpaceUser = (userEmail, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  axios
    .post(`${orgID}/mladmin/users/checkPrimaryOrgUser`, { user: userEmail }, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {

      if (done) done(res.data, null);
      
    })
    .catch((err) => {
      done(null, "Error");

    });
};