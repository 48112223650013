import axios from "axios";
import store from "redux/Store";

import {
  UPDATE_DOWNLOAD_LIST,
  ADD_DOWNLOADING_LIST,
  CLEAR_DOWNLOAD_LIST,
} from "./../Types";

export const clearDownloads = () => (dispatch) => {
  dispatch({ type: CLEAR_DOWNLOAD_LIST });
}

export const updateDownloads = (payload) => (dispatch) => {
  const {
    download: { tracksDownloading, trackDownloads },
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  if (
    !trackDownloads.has(payload.id) &&
    !tracksDownloading.includes(payload.id)
  ) {
    dispatch({ type: ADD_DOWNLOADING_LIST, payload: { id: payload.id, plId: payload.plId } });
    axios
      .post(
        `${orgID}/downloadeableassets`,
        { id: payload.id, plId: payload.plId },
        { headers: { orgid: orgID } }
      )
      .then((res) => {
          var downloads = res?.data?.links ? res.data.links : [];
          dispatch({
            type: UPDATE_DOWNLOAD_LIST,
            payload: { id: payload.id, plId: payload.plId, downloads },
          });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_DOWNLOAD_LIST,
          payload: { id: payload.id, plId: payload.plId, downloads: [] },
        });
      });
  }
};

export const getDownloadLinkByResolution = (id, resolution, searchId = null, plId = null) =>
new Promise(function (resolve, reject) {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  axios
  .post(`${orgID}/downloadeablelink`, { id, resolution, searchId, plId})
  .then((res) => {
      resolve(res?.data);
  })
  .catch((err) => {
    resolve('');
    });
});


export const getDownloadLink = (id, asset, searchId = null, plId = null) =>
new Promise(function (resolve, reject) {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  axios
  .post(`${orgID}/downloadeablelink`, { id, index: asset.index, searchId, plId })
  .then((res) => {
      //resolve(res?.data?.url ? res.data.url : '');
      resolve({url: res?.data?.url ? res.data.url : '', 
      ...(res?.data?.abuseFlag && {abuseFlag: res?.data?.abuseFlag}),
      ...(res?.data?.abuseMsg && {abuseMsg: res?.data?.abuseMsg})
      });
  })
  .catch((err) => {
    resolve('');
    });
});

export const getDownloadBookletLink = (id, asset) =>
new Promise(function (resolve, reject) {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  axios
  .post(`${orgID}/downloadeablebooklet`, { id, index: asset })
  .then((res) => {
      resolve({url: res?.data?.url ? res.data.url : ''});
  })
  .catch((err) => {
    resolve('');
    });
});