import React, { Component } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Grid,
  Switch,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import Avatar from "@material-ui/core/Avatar";
import ModuleEdit from "_module/component/module-edit/ModuleEdit";
import { _mlPlaylists } from "_module/definition/MlPlaylists";
import { _getModuleDef } from "_module/utils/ModuleUtils";
import { _newModuleInstance } from "_module/utils/newModuleInstance";
import { _mlPlayer } from "_module/definition/MlPlayer";
import { _mlPlaylistContentShare } from "_module/definition/MlPlaylistsContentShare";
import { PlaylistShareObjPermissions } from "./Columns";
import { deepCopyFunction } from "util/CommonFunc";

export default class PlaylistSharePermission extends Component {
  constructor(props) {
    super();
    this.state = {
      playListModules: {},
      musicPlayerModules: {},
      defaultRolePermission: null,
      defaultPlaylistPermission: null,
      defaultContentPermission: null
    };
  }

  componentDidMount() {
    const { addNewUsers, users } = this.props;
    if (addNewUsers) {
      // this.setState({ playListModules: _newModuleInstance(_mlPlaylistContentShare), musicPlayerModules: _newModuleInstance(_mlPlayer), defaultPlaylistPermission: true, defaultContentPermission: true })
      this.setState({ defaultRolePermission: true })
    }
    else {
      if (!users[0].permission[PlaylistShareObjPermissions.PLAYLIST] && !users[0].permission[PlaylistShareObjPermissions.CONTENT]) {
        this.setState({ defaultRolePermission: true })
      }
      else {
        this.setState({ playListModules: deepCopyFunction(users[0].permission[PlaylistShareObjPermissions.PLAYLIST]), musicPlayerModules: deepCopyFunction(users[0].permission[PlaylistShareObjPermissions.CONTENT]) })
      }

    }
  }

  handlePlaylistChildChange = (id, val) => {
    if (id == _mlPlaylistContentShare.id) {
      this.setState({ playListModules: val }, () => {
      })
    }
    else if (id == _mlPlayer.id) {
      this.setState({ musicPlayerModules: val }, () => {
      })
    }
  }

  hanldeAddPermission = () => {
    const { addPermission, users, groups } = this.props;

    const { defaultPlaylistPermission, defaultContentPermission, defaultRolePermission } = this.state;

    let pUsers = users;
    let pGroups = groups;

    if (pUsers && pUsers.length > 0) {
      pUsers.forEach(u => {
        if (defaultRolePermission) {
          u.permission[PlaylistShareObjPermissions.PLAYLIST] = null;
          u.permission[PlaylistShareObjPermissions.CONTENT] = null;
        }
        else {
          u.permission[PlaylistShareObjPermissions.PLAYLIST] = this.state.playListModules;
          u.permission[PlaylistShareObjPermissions.CONTENT] = this.state.musicPlayerModules;
        }

      });

    }

    if (pGroups && pGroups.length > 0) {
      pGroups.forEach(u => {
        if (defaultRolePermission) {
          u.permission[PlaylistShareObjPermissions.PLAYLIST] = null;
          u.permission[PlaylistShareObjPermissions.CONTENT] = null;
        }
        else {
          u.permission[PlaylistShareObjPermissions.PLAYLIST] = this.state.playListModules;
          u.permission[PlaylistShareObjPermissions.CONTENT] = this.state.musicPlayerModules;
        }

      });

    }
    addPermission(pUsers, pGroups);
  }

  handleDefaultPlaylistSwitchChange = (event) => {
    if (!event.target.checked) {
      this.setState({ defaultPlaylistPermission: null });
    }
    else {
      this.setState({ defaultPlaylistPermission: true, playListModules: _newModuleInstance(_mlPlaylistContentShare) });
    }
  }

  handleDefaultContentSwitchChange = (event) => {
    if (!event.target.checked) {
      this.setState({ defaultContentPermission: null });
    }
    else {
      this.setState({ defaultContentPermission: true, musicPlayerModules: _newModuleInstance(_mlPlayer) });
    }
  }

  handleDefaultRolePermSwitchChange = (event) => {
    if (!event.target.checked) {
      this.setState({ defaultRolePermission: null });
    }
    else {
      this.setState({ defaultRolePermission: true, musicPlayerModules: _newModuleInstance(_mlPlayer), playListModules: _newModuleInstance(_mlPlaylistContentShare) });
    }
  }

  render() {
    const {
      open,
      close,
      DialogBoxTitle,
      DialogBoxContentText,
      addPermission,
      classes,
      users,
      groups,
      addNewUsers
    } = this.props;

    const { playListModules, musicPlayerModules, defaultPlaylistPermission, defaultContentPermission, defaultRolePermission } = this.state;

// console.log("aaaaaaaaaaaaaaaaaaaa",groups, users )
    return (
      <Dialog
        open={open}
        key={"sharePlaylistPermission"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialogWrapper}
        class=" sharedUserPermissionModal"
      >
        <DialogTitle id="alert-dialog-title">

          <div className={classes.modalContentTitle}>
            {DialogBoxTitle}
            <Button
              aria-label="Close set permission"
              className={clsx(
                classes.closeButton
              )}
              title="Close"
              onClick={()=>{
                close();
              }}
            >
              <CloseIcon />
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <div>
                <h3>User(s)</h3>
                <div>
                  {users && users.length > 0 && (
                    users?.map((user) => {
                      return (
                        <>
                          <div className={classes.sharedUserList}>
                            <Avatar
                              alt={user.name}
                              src={user.imageUrl}
                              className={classes.smallAvatar}
                            ></Avatar>
                            <div className={user.isExternal && !user.hasPermission ? classes.extUser : ""}>
                              <div title={user.name} className="">
                                {user.name}
                                <span title={user.email} className={classes.sharedUserListEmail}>{user.email}</span>
                                 {/* {user.isExternal &&
                                <span title={user.orgName} className={clsx(classes.sharedUserListEmail, classes.sharedOrg)}>
                                  {user.orgName}
                                </span>} */}
                              </div>
                             
                            </div>

                          </div>
                        </>
                      )
                    })
                  )}
                  {groups && groups.length > 0 && (
                    groups?.map((group) => {
                      return (
                        <>
                          <div className={classes.sharedUserList}>
                            <Avatar
                              alt={group.name}
                              src={group.imageUrl}
                              className={classes.smallAvatar}
                            ></Avatar>
                            <div className={""}>
                              <div title={group.name} className="">
                                {group.name}
                              </div>
                              {/* {user.isExternal &&
                                <div title={user.orgName} className={clsx(classes.sharedUserListEmail, classes.sharedOrg)}>
                                  {user.orgName}
                                </div>} */}
                            </div>

                          </div>
                        </>
                      )
                    })
                  )}
                </div>
              </div>
            </Grid>


            <Grid item xs={12} sm={8}>
              <div title={"Keep default role permission"}>
                <Switch
                  name="defaultRolePermission"
                  classes={classes}
                  onChange={this.handleDefaultRolePermSwitchChange}
                  // disabled={editing.bbcSSO}
                  checked={this.state.defaultRolePermission}
                  inputProps={{
                    'aria-label': "Handle default role permission"
                  }}
                />
                <label
                  className={classes.addPermissionLabel}
                  aria-label="Keep default role permission"
                >
                  Keep default role permission
                </label>

              </div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>

                  <div>
                    <h3 className={classes.playlistHeaderTitle}>Playlist</h3>

                    {/* <div title={"Handle default playlist permission"}>
                  <label
                    className={classes.globalCreateLabel}
                    aria-label="Handle default playlist permission"
                  >
                    Default playlist permission
                  </label>

                  <Switch
                    name="handleDefaultPlaylistPermission"
                    classes={classes}
                    onChange={this.handleDefaultPlaylistSwitchChange}
                    // disabled={editing.bbcSSO}
                    checked={this.state.defaultPlaylistPermission}
                    inputProps={{
                      'aria-label': "Handle default playlist permission"
                    }}
                  />

                </div> */}
                    {defaultRolePermission && (
                      <div className={classes.playlistEmptyMessage}>
                        Default user role permission applied for playlist.

                      </div>
                    )}
                    {!defaultRolePermission && (
                      <div>

                        <ModuleEdit
                          key={_mlPlaylistContentShare.id}
                          //onRemove={(id) => this.handleRemove(id, v)}
                          module={playListModules}
                          moduleDef={_getModuleDef(_mlPlaylistContentShare.id)}
                          handleChildChange={(id, val) => {
                            this.handlePlaylistChildChange(id, val);
                          }}
                        />

                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div>
                    <h3>Content</h3>
                    {/* <div title={"Handle default content permission"}>
                  <label
                    className={classes.globalCreateLabel}
                    aria-label="Handle default content permission"
                  >
                    Default content permission
                  </label>

                  <Switch
                    name="handleDefaultContentPermission"
                    classes={classes}
                    onChange={this.handleDefaultContentSwitchChange}
                    // disabled={editing.bbcSSO}
                    checked={this.state.defaultContentPermission}
                    inputProps={{
                      'aria-label': "Handle default content permission"
                    }}
                  />

                </div> */}
                    {defaultRolePermission && (
                      <div>
                        Default user role permission applied for playlist contents.

                      </div>
                    )}
                    {!defaultRolePermission && (
                      <div class="PermissionselecBoxes">

                        <ModuleEdit
                          key={_mlPlayer.id}
                          //onRemove={(id) => this.handleRemove(id, v)}
                          module={musicPlayerModules}
                          moduleDef={_getModuleDef(_mlPlayer.id)}
                          handleChildChange={(id, val) => {
                            this.handlePlaylistChildChange(id, val);
                          }}
                        />

                      </div>
                    )}
                  </div>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {addNewUsers &&(
          <Button className={clsx(classes.addUsersButton, classes.dialogYesButton)} onClick={this.hanldeAddPermission} color="primary" autoFocus>
            Add Permission
          </Button>
          )}
          {!addNewUsers &&(
          <Button className={clsx(classes.addUsersButton, classes.dialogYesButton)} onClick={this.hanldeAddPermission} color="primary" autoFocus>
            Update Permission
          </Button>
          )}
          {/* <Button className={classes.dialogNoButton} onClick={close} color="primary">
            No
          </Button> */}

        </DialogActions>
      </Dialog>
    );
  }

}
