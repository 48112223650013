import { ruleOutcomeTypes } from "../redux/Types";
import { appConfig } from "../App";

export const activityLimitActions = (abuseFlag) => {
  if (
    abuseFlag == ruleOutcomeTypes.LOGOUT ||
    abuseFlag == ruleOutcomeTypes.DEACTIVATE
  )
    // Logout user
    window.location.replace(`${appConfig.ORG_CODE}/signin`);
};
