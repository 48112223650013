import {
  T_LeafBool,
  T_LeafOptionsMultiOrdered,
  T_Major,
} from "./ModulePropTypes";
import { PT_Setting } from "./ModulePermitTypes";
import albumFields from "./albumFields";
// import albumOrigins from "./albumOrigins";
import albumAllFileds from "./albumAllFileds";
import { appConfig } from "App";

const albumOrigins = window._env_?.ALBUM_MUSIC_ORIGINS;

export const _settingAlbumSearchShowGlobalQ = {
  id: "ass0",
  permitType: PT_Setting,
  type: T_LeafBool,
  default: true,
};

export const _settingAlbumSearchShowAllFields = {
  id: "ass1",
  permitType: PT_Setting,
  type: T_LeafBool,
  default: true,
};

export const _settingAlbumSearchAllFields = {
  id: "ass11",
  permitType: PT_Setting,
  type: T_LeafOptionsMultiOrdered,
  options: () => {
    let nonetagtype = {};
    Object.keys(albumFields).map((key) => {
      if (!albumFields[key].sys)
        nonetagtype[key] = albumFields[key];
    });
    return nonetagtype;
  },
  // default: [
  //   albumFields.catNo.id,
  //   albumFields.prodName.id,
  //   albumFields.prodArtist.id,
  //   albumFields.ipRecordLabel.id,
  //   albumFields.prodYear.id,
  //   albumFields.prodRelease.id,
  //   albumFields.prodNumberOfDiscs.id,
  //   albumFields.prodDiscNr.id,
  //   albumFields.musicOrigin.id,
  //   albumFields.prodTags.id,
  //   albumFields.trackTempo.id,
  //   albumFields.trackBpm.id,
  //   albumFields.trackGenres.id,
  //   albumFields.trackInstruments.id,
  //   albumFields.trackStyles.id,
  //   albumFields.upc.id,
  //   albumFields.ean.id,
   
  //   albumFields.trackTitle.id,
  //   albumFields.trackIsrc.id,
  //   albumFields.trackIswc.id,
  //   albumFields.trackPrs.id,
  //   albumFields.trackComposer.id,
  //   albumFields.trackLyricist.id,
  //   albumFields.trackAdaptor.id,
  //   albumFields.trackAdministrator.id,
  //   albumFields.trackArranger.id,
  //   albumFields.trackPublisher.id,
  //   albumFields.trackOriginalPublisher.id,
  //   albumFields.trackPerformer.id,
  //   albumFields.trackRecordLabel.id,
  //   albumFields.trackSubLyricist.id,
  //   albumFields.trackSubAdaptor.id,
  //   albumFields.trackSubArranger.id,
  //   albumFields.trackSubPublisher.id,
  //   albumFields.trackTranslator.id,
  
  //   albumFields.libNotes.id,
  //   albumFields.prodNotes.id,
  //   albumFields.trackNotes.id,
   
  //   albumFields.libId.id,
  //   albumFields.libName.id,
  //   albumFields.wsId.id,
  //   albumFields.wsName.id,
  // ],
  default: albumAllFileds
};

export const _settingAlbumSearchShowAllTagTypes = {
  id: "ass2",
  permitType: PT_Setting,
  type: T_LeafBool,
  default: true,
};

export const _settingAlbumSearchAllTagTypes = {
  id: "ass21",
  permitType: PT_Setting,
  type: T_LeafOptionsMultiOrdered,
  options: () => {
    let tagtype = {};
    Object.keys(albumFields).map((key) => {
      if (albumFields[key].tag) tagtype[key] = albumFields[key];
    });
    return tagtype;
  },
  default: [
    albumFields.trackGenres.id,
    albumFields.trackInstruments.id,
    albumFields.trackStyles.id,
    albumFields.trackMoods.id,
    albumFields.trackKeywords.id,
    albumFields.prodTags.id,
    albumFields.trackTempo.id,
    albumFields.trackBpm.id,
  ],
};

export const _settingAlbumSearchOrigin = {
  id: "ass3",
  permitType: PT_Setting,
  type: T_LeafOptionsMultiOrdered,
  options: albumOrigins,
  default: Object.keys(albumOrigins),
};

export const _settingAlbumSearchFields = {
  id: "ass4",
  permitType: PT_Setting,
  type: T_LeafOptionsMultiOrdered,
  options: () => {
    let flds = {};
    Object.keys(albumFields).map((key) => {
      if (!albumFields[key].sys) flds[key] = albumFields[key];
    });
    return flds;
  },
  default: [albumFields.trackTitle.id, albumFields.prodArtist.id],
};

export const _settingAlbumAllKeyFields = {
  id: "ass5",
  permitType: PT_Setting,
  type: T_LeafOptionsMultiOrdered,
  options: () => {
    let flds = {};
    Object.keys(albumFields).map((key) => {
      if (!albumFields[key].sys) flds[key] = albumFields[key];
    });
    return flds;
  },
  default: [albumFields.prodName.id, albumFields.prodArtist.id],
};

export const _settingAlbumMusicSearch = {
  id: "settingAlbumMusicSearch",
  permitType: PT_Setting,
  type: T_Major,
  modules: [
    _settingAlbumSearchShowGlobalQ,
    // _settingAlbumSearchShowAllFields, removed search all fields
    _settingAlbumSearchShowAllTagTypes,
    _settingAlbumSearchAllFields,
    _settingAlbumSearchAllTagTypes,
    _settingAlbumSearchOrigin,
    _settingAlbumSearchFields,
    _settingAlbumAllKeyFields,
  ],
};
