import { appConfig } from "App";
import { actionTypes } from "./TagCloudActions";
// import trackOrigins from "../../../_module/definition/trackOrigins";
const trackOrigins = window._env_?.TRACK_MUSIC_ORIGINS;
const initialState = {
  open: false,
  cloudField: null,
  items: [],
  loading: false,
  query: {
    module: "track",
    origin: trackOrigins.originall.val,
    size: 50,
    tagType: "all",
    filters: [],
  },
  errors: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.OPEN:
      return {
        ...state,
        open: true,
        cloudField: action.payload,
      };

    case actionTypes.CLOSE:
      return {
        ...state,
        open: false,
        cloudField: null,
        query: {
          module: "track",
          origin: trackOrigins.originall.val,
          size: 50,
          tagType: "all",
          filters: [],
        },
      };
    case actionTypes.LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
        items: [],
      };
    case actionTypes.LOAD:
      return {
        ...state,
        loading: false,
        errors: null,
        items: action.payload,
      };

    case actionTypes.ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        items: [],
      };

    case actionTypes.QUERY:
      return {
        ...state,
        query: action.payload,
      };

    default:
      return state;
  }
}
