import axios from "axios";
import store from "redux/Store";
import { pagingTotalRelation, userOrgStatus } from "redux/Types";
import { v4 as uuidv4 } from "uuid";
import { ValidateContentRequest } from "../../../util/validations/MessageComposeValidation";
import { messageTypes, playlistShareRequestAction } from "../Const";
import { appConfig } from "App";
module = "/messages";
export const actionTypes = {

  NEW_COUNT: "NEW_COUNT" + module,
  NEW_UNREAD_COUNT: "NEW_UNREAD_COUNT" + module,
  EDIT_ITEM: "EDIT_ITEM" + module,
  EDIT_ITEMS_SAVECB : "EDIT_ITEMS_SAVECB" + module,
  CLOSE_MESSAGECB : "CLOSE_MESSAGECB" + module,
  UPDATE_CONTENTREQ_STATUS : "UPDATE_CONTENTREQ_STATUS" + module,

  POSTING: "POSTING" + module,
  POST_OK: "POSTOK" + module,
  POST_ERROR: "POST_ERROR" + module,

  PLAYLIST_REQUEST_POSTING: "PLAYLIST_REQUEST_POSTING" + module,
  PLAYLIST_REQUEST_POST_OK: "PLAYLIST_REQUEST_POSTOK" + module,
  PLAYLIST_REQUEST_POSTING_ERROR: "PLAYLIST_REQUEST_POSTING_ERROR" + module,
  PLAYLIST_REQUEST_POST_ERROR: "PLAYLIST_REQUEST_POST_ERROR" + module,


  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  SETTING_AS_READ: "SETTING_AS_READ" + module,
  SETTING_AS_READ_COMPLETE: "SETTING_AS_READ_COMPLETE" + module,
  SETTING_AS_READ_ERROR: "SETTING_AS_READ_ERROR" + module,

  PLAYLIST_PERMISSION_LOADING: "PLAYLIST_PERMISSION_LOADING" + module,
  PLAYLIST_PERMISSION_LOAD: "PLAYLIST_PERMISSION_LOAD" + module,
  PLAYLIST_PERMISSION_ERROR: "PLAYLIST_PERMISSION_ERROR" + module,

  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  QUERY: "QUERY" + module,

  USE_POPUP: "USE_POPUP" + module, 

  PLAYLIST_SET_PERMITED_ORG: "PLAYLIST_SET_PERMITED_ORG" + module,

  REDIRECT_MESSAGE_OPEN: "REDIRECT_MESSAGE_OPEN" + module,
  REDIRECT_MESSAGE_CLOSE: "REDIRECT_MESSAGE_CLOSE" + module,

};

export const setUsePopup = (usePopup, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.USE_POPUP,
    payload: usePopup,
  });
};

export const setNewCount = (count) => (dispatch) => {
  dispatch({
    type: actionTypes.NEW_COUNT,
    payload: count,
  });
};

export const setUnreadCount = (count) => (dispatch) => {
  dispatch({
    type: actionTypes.NEW_UNREAD_COUNT,
    payload: count,
  });
};

export const editMessage = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.EDIT_ITEM,
    payload: item,
  });
};

export const setEditMessageCB = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.EDIT_ITEMS_SAVECB,
    payload: item,
  });
};

export const updateContentReqStatus = (action, message, done = null) => (dispatch) => {

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  axios
    .post(`${orgID}/messages/${action}`, message, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (done) done(res.data, null);
      //}
    })
    .catch((err) => {
          if (done) done(null, err);
    });
  
};


export const closeMessageCB = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.CLOSE_MESSAGECB,
    payload: item,
  });
};

export const validateCR = (data, done = null) => (dispatch) => {
  if (data) {
    let errors = ValidateContentRequest(data);
    if (done) done(errors);
  }
};

export const postMessage = (action, message, item, done = null) => (dispatch) => {
  

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/messages/${action}`, item, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      //if (res.data.tid === tId) {
      dispatch({
        type: actionTypes.POST_OK,
      });

      if (done) done(res.data, null);
      //}
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        // payload: { e: err },
        payload: err.response?.data || {
          e: err.message
            ? err.message
            : "Error in creating or updating. please retry",
        },
      });

      if (done) done(null, err);
    });
};

export const setQuery = (query, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.QUERY,
    payload: query,
  });

  if (done) done();
};

export const selectItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};

export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });
};

export const redirectMessageOpen = () => (dispatch) => {
  dispatch({
    type: actionTypes.REDIRECT_MESSAGE_OPEN,
  });
};

export const redirectMessageClose = () => (dispatch) => {
  dispatch({
    type: actionTypes.REDIRECT_MESSAGE_CLOSE,
  });
};
export const search = (query, searchOnClose = false) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  const tId = uuidv4();
  
  if(!searchOnClose){
    dispatch({
      type: actionTypes.LOADING,
    });
  }

  axios
    .post(`${orgID}/messages/search`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.LOAD,
          payload: res.data.results,
          total: res.data.total,
          unread: res.data.totalUnreadCount,
          totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
        });
        
        checkPlaylistOrgPermission(res.data.results,null,dispatch);
       
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
    });
};
export const setPlaylistPermittedOrgs = (orgs) => (dispatch) => {
  const {
    user: {
      credentials: { orgs },
    },
  } = store.getState();

  let orgList = {};

  if(orgs.length > 0){
    orgs.map(o => { 
      orgList[o.id] = {
        busy : false,
        userStatus: userOrgStatus.ACTIVE,
        errors : null
      }
    })
  }

  dispatch({
    type: actionTypes.PLAYLIST_SET_PERMITED_ORG,
    payload: orgList,
  });
}

export const checkPlaylistOrgPermission = (items = [], done = null,dispatch) => {

  const {
    user: {
      email,
      credentials: { orgs, orgID },
    },
  } = store.getState();

  let plMessages = [];
  plMessages = items.filter(m => ( // filter shared playlist messages from the search results which are not already included in the "plMessages"
    (m.type === messageTypes.PLAYLIST || m.type === messageTypes.INVITATION) 
    && m.attachments?.length > 0 
    && m.attachments[0].sharedDetails?.sharedOrgId
    && plMessages.findIndex(d => d.orgId === m.attachments[0].sharedDetails.sharedOrgId) === -1
    )).map(mItem => {return {email : email, orgId : mItem.attachments[0].sharedDetails.sharedOrgId}})
    .filter((value, index, self) => // remove duplicates of the array
            index === self.findIndex((t) => (
              t.orgId === value.orgId 
              // && (orgs.findIndex(v => v.id === t.orgId) === -1)
            )));

     if(plMessages.length > 0){
      plMessages.forEach(plOrgItem => {

        dispatch({
          type: actionTypes.PLAYLIST_PERMISSION_LOADING,
          payload: { orgId : plOrgItem.orgId },
        });

        axios
      .post(`checkUserPermissionForPlaylist`, plOrgItem,{
        headers: { orgid: orgID },
      } )
      .then((res) => {
        if(res.data){
          dispatch({
            type: actionTypes.PLAYLIST_PERMISSION_LOAD,
            payload: { 
              orgId : res.data.orgId || plOrgItem.orgId,
              userStatus: res.data.status, 
              handle: res.data.handle
              }
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.PLAYLIST_PERMISSION_ERROR,
          payload: { orgId : plOrgItem.orgId, error: "Error loading user permission for playlist." },
        });
      });


      });
      
    }

}


export const setMessageAsRead = (done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  const tId = uuidv4();
  dispatch({
    type: actionTypes.SETTING_AS_READ,
  });

  axios
    .post(`${orgID}/messages/setasread`, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.SETTING_AS_READ_COMPLETE,
        payload: res,
      });
      if(done) done(res,null)
      
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.SETTING_AS_READ_ERROR,
        payload: { e: err },
      });
      if(done) done(null,err)
    });
};

export const sharedPlaylistRequest = (action,  item, message, done = null) => (dispatch)=>{
  const {
    user: {
      credentials: { orgID },
    },
    messages: {
      permittedOrgs
    }
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.PLAYLIST_REQUEST_POSTING,
    payload: {
      message : message,
      action: action,
      org: item.sharedOrgId
    },
  });
  axios
    .post(`${orgID}/messages/${action === playlistShareRequestAction.ACCEPT ? "accept" : "reject"}`, item, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.PLAYLIST_REQUEST_POST_OK,
      });
      let URL = `https://${item.sharedOrgId}.${appConfig.MB_DOMAIN}/signuporg/${permittedOrgs[item.sharedOrgId].handle}`
      // let URL = `http://localhost:3000/signuporg/${permittedOrgs[item.sharedOrgId].handle}`
      console.log(URL)
      let win = window.open(URL, "_blank");
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.PLAYLIST_REQUEST_POSTING_ERROR,
        payload: {
          errors : `Error ${action === playlistShareRequestAction.ACCEPT ? "accepting" : "rejecting"} request.`,
          org: item.sharedOrgId
        },
      });

      setTimeout(() => {
      dispatch({
        type: actionTypes.PLAYLIST_REQUEST_POST_ERROR,
        payload: {
          errors : `Error ${action === playlistShareRequestAction.ACCEPT ? "accepting" : "rejecting"} request.`,
          org: item.sharedOrgId
        },
      });
      }, 3000);


      if (done) done(null,err);
    });
}
