export default {
  CPT_OptionsYesno: 1,
  CPT_OptionsYesnoRadio: 111,
  CPT_OptionsYesnoSwitch: 112,
  CPT_OptionsSingle: 2,
  CPT_OptionsSingleRadio: 221,
  CPT_OptionsMulti: 3,
  CPT_OptionsMultiCheck: 331,
  CPT_OptionsText: 4,
  CPT_OptionsNumber: 5,
  CPT_OptionsDate: 6,
  CPT_OptionsTime: 7,
  CPT_OptionsDateTime: 8,
  CPT_OptionsDateRange: 9,
  CPT_OptionsTimeRange: 10,
  CPT_OptionsDateTimeRange: 11,
  CPT_InputDuration: 12,
};
// This type is also in CentralAPI, keep consistent if you modify it