import { actionTypes } from "../actions/MlPlaylistOutboxActions";
import { SortTypes } from "../../util/table-view/TableTypes";
import { FilterOperatorTypes } from './../../util/table-view/TableTypes';
import { defaultResultsPerPage, pagingTotalRelation } from "../Types";

const initialState = {
  items: [],
  totalItems: 0,
  totalRelation: pagingTotalRelation.EQUALS,
  selectedItem: {},
  selectedItems: [],
  loading: false,
  posting: { busy: false, message: "", errors: null },
  editing: null,
  plrecent: { busy: false, message: "", errors: null },
  orgusers: null,
  plrecentList: null,
  plrecentQuery: null,
  sysAllUsers: null,
  query: {
    order: SortTypes.desc,
    orderBy: "updatedAt",
    page: 0,
    size: defaultResultsPerPage,
    q: "",
    filters: [{ operator: FilterOperatorTypes.equals, value: [1], field: "status" }],
  },
  errors: null,
};

export default function (state = initialState, action) {

  switch (action.type) {
    case actionTypes.LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
        selectedItems: [],
        items: [],
      };

    case actionTypes.LOAD:
      return {
        ...state,
        loading: false,
        errors: null,
        items: action.payload,
        totalItems: action.total,
        totalRelation: action.totalRelation,
        selectedItem: action.payload[0] || {},
        selectedItems: [],
      };

    case actionTypes.ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        items: [],
        totalItems: 0,
        totalRelation: pagingTotalRelation.EQUALS,
      };

    case actionTypes.SELECT_ITEMS:
      return {
        ...state,
        selectedItems: action.payload,
      };
    case actionTypes.QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case actionTypes.FOCUS_ITEM:
      return {
        ...state,
        selectedItem: action.payload,
      };

    case actionTypes.EDIT_ITEM:
      return {
        ...state,
        editing: action.payload,
      };

    case actionTypes.POSTING:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.POST_OK:
      var items = state.items.find((r) => r.id === action.payload.id)
        ? state.items.map((i) =>
          i.id === action.payload.id ? action.payload : i
        )
        : [action.payload, ...state.items];

      return {
        ...state,
        items,
        posting: {
          busy: false,
          message: "",
          errors: null,
        },
      };

    case actionTypes.POST_ERROR:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: action.payload,
        },
      };

    case actionTypes.PL_UPDATING:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.PL_UPDATE_OK:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: null,
        },
      };

    case actionTypes.PL_UPDATE_ERROR:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: action.payload,
        },
      };

    case actionTypes.SET_PLRECENT_Q:
      return {
        ...state,
        plrecentQuery: action.payload,
      };

    case actionTypes.LOADING_EDITABLE_PL:
      return {
        ...state,
        posting: {
          busy: true,
          message: "",
          errors: null,
        },
      };

    case actionTypes.LOAD_EDITABLE_PL:
      return {
        ...state,
        plrecentList: action.payload,
        posting: {
          busy: false,
          message: "",
          errors: null,
        },
      };

    case actionTypes.CLEAR_ERRORS:
      return {
        ...state,
        posting: {
          errors: action.payload,
        },
      };

    case actionTypes.SET_SYS_ALL_USERS:
      return {
        ...state,
        sysAllUsers: action.sysAllUsers,
      };

    case actionTypes.CLEAR_SYS_ALL_USERS:
      return {
        ...state,
        sysAllUsers: null,
      };


    default:
      return state;
  }
}
