import { __cfStatus } from "../definition/status";
import ParamObjectTypes from "../definition/ParamObjectTypes";
import sourceMetadataTypes from "../definition/sourceMetadataTypes.js";
import store from "redux/Store";
import trackCTags from "../../_module/definition/trackCTags";
import { actionTypes } from "../../redux/actions/ClearanceRuntimeActions";
import trackFields from "../../_module/definition/trackFields";
import { deepCopyFunction } from "util/CommonFunc";
import { appConfig } from "../../App";
import moment from "moment";
import paramTypes from "_clearance/definition/paramTypes";

const _0x3690 = [
  "weight",
  "1skPdfI",
  "forEach",
  "status",
  "push",
  "185063OoGuXG",
  "1mDIwFx",
  "29esHIuS",
  "gate",
  "1037041nxGpCR",
  "1offArJ",
  "15265HBUZvk",
  "466451sVAxBO",
  "name",
  "1BrWtqK",
  "sort",
  "953831heSCvR",
  "773467oKAAuK",
  "3337901tTwwxK",
];
function _0x31c2(_0x5ccc6a, _0x42e064) {
  _0x5ccc6a = _0x5ccc6a - 0x181;
  let _0x369046 = _0x3690[_0x5ccc6a];
  return _0x369046;
}
(function (_0x1d008e, _0x519218) {
  const _0x560606 = _0x31c2;
  while (!![]) {
    try {
      const _0x33f247 =
        -parseInt(_0x560606(0x187)) * -parseInt(_0x560606(0x183)) +
        -parseInt(_0x560606(0x182)) * -parseInt(_0x560606(0x181)) +
        parseInt(_0x560606(0x186)) * parseInt(_0x560606(0x185)) +
        -parseInt(_0x560606(0x18a)) * -parseInt(_0x560606(0x18c)) +
        -parseInt(_0x560606(0x190)) * -parseInt(_0x560606(0x18d)) +
        parseInt(_0x560606(0x188)) +
        -parseInt(_0x560606(0x18e));
      if (_0x33f247 === _0x519218) break;
      else _0x1d008e["push"](_0x1d008e["shift"]());
    } catch (_0x326020) {
      _0x1d008e["push"](_0x1d008e["shift"]());
    }
  }
})(_0x3690, 0x7f1bd);
export const __compileRules = (_0x4a266d, _0x55b7d6) => {
  const _0x77bffa = _0x31c2;
  let _0x1c8c94 = [];
  return (
    _0x4a266d["forEach"]((_0x3bb63a) => {
      const _0x8d575 = _0x31c2;
      (_0x3bb63a?.["rules"] || [])[_0x8d575(0x191)]((_0x14f4cc, _0x4b0a55) => {
        const _0x41af07 = _0x8d575;
        _0x1c8c94[_0x41af07(0x193)]({
          ..._0x14f4cc,
          collectionId: _0x3bb63a["id"],
          collectionPos: _0x4b0a55,
          colName: _0x3bb63a[_0x41af07(0x189)],
        });
      });
    }),
    _0x1c8c94[_0x77bffa(0x18b)]((_0x15cf0b, _0x5cb26c) => {
      const _0x8502f4 = _0x77bffa;
      let _0x8b9d04 =
          __getTrackStatusByID(
            _0x15cf0b?.[_0x8502f4(0x184)][0x0]?.[_0x8502f4(0x192)],
            _0x55b7d6
          )[_0x8502f4(0x18f)] || 0x0,
        _0x1f929c =
          __getTrackStatusByID(
            _0x5cb26c?.[_0x8502f4(0x184)][0x0]?.[_0x8502f4(0x192)],
            _0x55b7d6
          )["weight"] || 0x0;
      return _0x1f929c - _0x8b9d04;
    })
  );
};

export const __getConditionHelpText = (rId, conditionId, allRules) => {
  let rule = allRules.find((v) => v.id === rId) || { rules: [] };
  let condition = rule.rules.find((v) => v.id === conditionId) || {};
  return condition.helpText || "";
};

export const __setNotes = (cf, trackId, notes) => {
  const _notes = __getNotes(cf, trackId);
  if (_notes) {
    _notes.v = notes;
    _notes.unconfirmed = false;
  } else
    cf.props.push({
      id: "notes",
      v: notes,
      trackId,
      unconfirmed: false,
    });
};

export const __getServiceInfo = (cf) => {
  let lreturn = {
    serviceGroup: "",
    service: "",
    northAmericanCR: "No",
    hasRadioServcieExemption: "No",
    serviceSpeechRadioRSS: "",
  };
  if (!cf.commits || Object.keys(cf.commits).length === 0) return lreturn;
  let key = Object.keys(cf.commits)[0];
  let serviceGroupQ = (cf.commits[key]?.next || []).find((v) => v.order === 1);
  let serviceQ = (cf.commits[key]?.next || []).find((v) => v.order === 2);
  let northAmericanCRQ = (cf.commits[key]?.next || []).find((v) => v.id == 107);
  let hasRadioServcieExemptionQ = (cf.commits[key]?.next || []).find(
    (v) => v.id == 108
    );
  let serviceSpeechRadioRSSQ = (cf.commits[key]?.next || []).find((v) => v.id == 112);

  // if (!serviceGroupQ || !serviceQ || !northAmericanCRQ || !hasRadioServcieExemptionQ) return lreturn;

  if (serviceGroupQ) {
    lreturn.serviceGroup = (
      cf.props.find((p) => p.id === serviceGroupQ.id) || { v: "" }
    ).v;
  }
  if (serviceQ) {
    lreturn.service = (
      cf.props.find((p) => p.id === serviceQ.id) || { v: "" }
    ).v;
  }
  if (northAmericanCRQ) {
    lreturn.northAmericanCR = (
      cf.props.find((p) => p.id === northAmericanCRQ.id) || { v: "" }
    ).v;
  }
  if (hasRadioServcieExemptionQ) {
    lreturn.hasRadioServcieExemption = (
      cf.props.find((p) => p.id === hasRadioServcieExemptionQ.id) || { v: "" }
    ).v;
  }
  if (serviceSpeechRadioRSSQ) {
    lreturn.serviceSpeechRadioRSS = (
      cf.props.find((p) => p.id === serviceSpeechRadioRSSQ.id) || { v: "" }
    ).v;
  }
  return lreturn;
};

export const __getNotes = (cf, trackId) => {
  return cf.props.find((v) => v.id === "notes" && v.trackId === trackId);
};

export const __getStatus = (cf, trackId) => {
  if (!trackId) trackId = "";
  return cf.props.find((v) => v.id === "status" && v.trackId === trackId);
};

export const __getMessageId = (cf, trackId) => {
  if (!trackId) trackId = "";
  return cf.props.find((v) => v.id === "messageid" && v.trackId === trackId);
};

export const __setMessageId = (cf, trackId, messageid) => {
  const _id = __getMessageId(cf, trackId);
  if (_id) {
    _id.v = messageid;
  } else
    cf.props.push({
      id: "messageid",
      v: messageid,
      trackId,
    });
};

export const __getCFStatusByID = (id) => {
  return __cfStatus[id] || { id: "notfound", label: "Not Found", color: "" };
};

export const __getTrackStatusByID = (id, trackStatus) => {
  return (
    trackStatus.find((v) => v.id === id) || {
      id: "notfound",
      label: "Not Found",
      color: "",
    }
  );
};

export const __setStatus = (
  cf,
  status,
  ifstatus,
  trackId,
  commit,
  override = 0,
  overrideNotes = ""
) => {
  const { user } = store.getState();

  if (!trackId) trackId = "";
  const _status = __getStatus(cf, trackId);

  if (_status) {
    if (ifstatus) {
      if (_status.v === ifstatus) {
        _status.v = status;
        _status.unconfirmed = commit ? false : true;
        _status.by = user.id;
        _status.at = new Date().toISOString();
        _status.adminOverridden = override;
        _status.adminOverriddenNotes = overrideNotes;
      }
    } else {
      _status.v = status;
      _status.unconfirmed = commit ? false : true;
      _status.by = user.id;
      _status.at = new Date().toISOString();
      _status.adminOverridden = override;
      _status.adminOverriddenNotes = overrideNotes;
    }
  } else
    cf.props.push({
      id: "status",
      v: status,
      trackId,
      unconfirmed: commit ? false : true,
      by: user.id,
      at: new Date().toISOString(),
      adminOverridden: override,
      adminOverriddenNotes: overrideNotes,
    });
};

export const __getProps = (cf, trackId) => {
  if (!trackId)
    return cf.props.filter((v) => v.id !== "status" && v.trackId === "");
  else
    return cf.props.filter((v) => v.id !== "status" && v.trackId === trackId);
};

export const __getProp = (props, propid) => {
  return props.find((v) => v.id === propid) || null;
};

export const _getDefaultProp = (param, trackId) => {
  return {
    id: param.id,
    trackId: trackId ? trackId : "",
    v: param?.options[0]?.id || "",
  };
};

export const __initProp = (clearance, param, trackId) => {
  param = { ...param };
  let props = __getProps(clearance, trackId);
  let prop = __getProp(props, param.id);
  if (!prop) {
    clearance.props.push({
      id: param.id,
      trackId: trackId ? trackId : "",
      v: param?.options[0]?.id || "",
      unconfirmed: true,
      changed: param?.options && param?.options.length == 1 ? true : false,
    });

    param.trackId = trackId ? trackId : "";
  }
};

export const __setProp = (clearance, param, value) => {
  let props = __getProps(clearance, param.trackId);
  let prop = __getProp(props, param.id);
  prop.v = value;
  prop.changed = true;
};

export const _unconfirmById = (clearance, trackId) => {
  clearance.props = clearance.props.filter((v) => v.trackId !== trackId);
};

export const _commitProp = (clearance, param, trackId, unconfirmed = false) => {
  param = { ...param };
  let props = __getProps(clearance, trackId);
  let prop = __getProp(props, param.id);
  prop.unconfirmed = unconfirmed;
};

export const __setAutoProp = async (clearance, param, trackId) => {
  let props = __getProps(clearance, trackId);
  let prop = __getProp(props, param.id);
  let v = await __getAutoVal(clearance.tLookup, param, trackId);

  if (!prop) {
    clearance.props.push({
      id: param.id,
      trackId: trackId ? trackId : "",
      v: v,
      unconfirmed: false,
    });
  } else prop.unconfirmed = false;
};

export const __setAutoCFProp = async (clearance, param) => {
  let props = __getProps(clearance);
  let prop = __getProp(props, param.id);
  let v = await __getAutoCFVal(clearance, param);
  if (!prop) {
    clearance.props.push({
      id: param.id,
      trackId: "",
      v: v,
      unconfirmed: false,
    });
  } else prop.unconfirmed = false;
};

const _0x4880 = [
  "971qKnHgu",
  "52859QzqZAP",
  "options",
  "70553zHbcdm",
  "212NdyANn",
  "19271WWuMvc",
  "187107amCScW",
  "find",
  "93407YsSFKf",
  "field",
  "default",
  "1QjDHss",
  "212317IaJcCm",
];
(function (_0x3d831c, _0x492a44) {
  const _0x3162f3 = _0x1091;
  while (!![]) {
    try {
      const _0x2ee341 =
        -parseInt(_0x3162f3(0x1c8)) +
        parseInt(_0x3162f3(0x1ce)) +
        parseInt(_0x3162f3(0x1cf)) +
        parseInt(_0x3162f3(0x1cd)) * parseInt(_0x3162f3(0x1c9)) +
        parseInt(_0x3162f3(0x1cc)) * parseInt(_0x3162f3(0x1c7)) +
        -parseInt(_0x3162f3(0x1d1)) +
        -parseInt(_0x3162f3(0x1ca));
      if (_0x2ee341 === _0x492a44) break;
      else _0x3d831c["push"](_0x3d831c["shift"]());
    } catch (_0x525e00) {
      _0x3d831c["push"](_0x3d831c["shift"]());
    }
  }
})(_0x4880, 0x1e528);
function _0x1091(_0x26e1ff, _0x521b13) {
  _0x26e1ff = _0x26e1ff - 0x1c5;
  let _0x48800f = _0x4880[_0x26e1ff];
  return _0x48800f;
}
export const _getTrackAuto = (_0x4575aa, _0x5ceffd, _0x22bc00) => {
  const _0x5175d8 = _0x1091;
  let _0x267c06 = _0x4575aa[_0x22bc00] || {},
    _0x3ffc1c =
      _0x267c06[_0x5ceffd[_0x5175d8(0x1c5)]] ||
      _0x5ceffd[_0x5175d8(0x1c6)] ||
      "",
    _0xcf4924 = _0x5ceffd[_0x5175d8(0x1cb)][_0x5175d8(0x1d0)](
      (_0xdd82b1) => _0xdd82b1["id"] === _0x3ffc1c
    );
  return _0xcf4924 ? _0xcf4924["id"] : "unknown";
};

export const _getCFAuto = (clearance, param) => {
  switch (param.type) {
    case sourceMetadataTypes.CF_NAME:
      return clearance["name"];
    // case sourceMetadataTypes.CF_GENRE:
    //   return clearance["genre"];
    // case sourceMetadataTypes.CF_DEADLINE:
    //   return clearance["deadline"];
    // case sourceMetadataTypes.CF_STATUS:
    //   return clearance["clearancestatus"];
    case sourceMetadataTypes.CF_PROGRAMMETYPE:
      return clearance["programmeType"];
    case sourceMetadataTypes.CF_CLEARANCEFORMTYPE:
      return clearance["clearanceFormType"];
    case sourceMetadataTypes.CF_PRODUCERTYPE:
      return clearance["producerType"];
    case sourceMetadataTypes.CF_INPUTGROUP:
      return clearance["inputGroup"];
    case sourceMetadataTypes.CF_DEPARTMENT:
      return clearance["department"];
    case sourceMetadataTypes.CF_CHANNEL:
      return clearance["channel"];
    // case sourceMetadataTypes.CF_PRODUCTIONCOMPANY:
    //   return clearance["productionCompany"];
    // case sourceMetadataTypes.CF_PRODUCTIONCOMPANYCONTACT:
    //   return clearance["productionCompanyContact"];
    case sourceMetadataTypes.CF_SERIESTITLE:
      return clearance["seriesTitle"];
    case sourceMetadataTypes.CF_PROMOTIONID:
      return clearance["promotionId"];
    case sourceMetadataTypes.CF_PROMOTIONTITLE:
      return clearance["promotionTitle"];
    case sourceMetadataTypes.CF_PROGRAMMETITLE:
      return clearance["programmeTitle"];
    case sourceMetadataTypes.CF_EPISODETITLE:
      return clearance["episodeTitle"];
    case sourceMetadataTypes.CF_PRODNUMBER:
      return clearance["prodNumber"];
    case sourceMetadataTypes.CF_PRODUCTIONNAME:
      return clearance["productionName"];
    case sourceMetadataTypes.CF_CONTRACTNUMBER:
      return clearance["contractNumber"];
    case sourceMetadataTypes.CF_EPISODENAME:
      return clearance["episodeName"];
    case sourceMetadataTypes.CF_EPISODENUMBER:
      return clearance["episodeNumber"];
    case sourceMetadataTypes.CF_PROMOONEOFFHEADERQ1:
      return clearance["promoOneoffHeaderQ1"];
    case sourceMetadataTypes.CF_PROMOONEOFFHEADERQ2:
      return clearance["promoOneoffHeaderQ2"];
    case sourceMetadataTypes.CF_PROMOONEOFFHEADERQ2Sub1:
      return clearance["promoOneoffHeaderQ2Sub1"];
    case sourceMetadataTypes.CF_PROMOONEOFFHEADERQ3:
      return clearance["promoOneoffHeaderQ3"];
    case sourceMetadataTypes.CF_PROMOONEOFFHEADERQ3Sub1:
      return clearance["promoOneoffHeaderQ3Sub1"];
    case sourceMetadataTypes.CF_PROMOONEOFFHEADERQ4:
      return clearance["promoOneoffHeaderQ4"];
    case sourceMetadataTypes.CF_PROMOONEOFFHEADERQ4Sub1:
      return clearance["promoOneoffHeaderQ4Sub1"];
    case sourceMetadataTypes.CF_PROMOONEOFFHEADERQ5:
      return clearance["promoOneoffHeaderQ5"];
    case sourceMetadataTypes.CF_PROMOONEOFFHEADERQ5Sub1:
      return clearance["promoOneoffHeaderQ5Sub1"];
    case sourceMetadataTypes.CF_PROMOONEOFFHEADERQ6:
      return clearance["promoOneoffHeaderQ6"];
    case sourceMetadataTypes.CF_PROMOONEOFFHEADERQ7:
      return clearance["promoOneoffHeaderQ7"];
    case sourceMetadataTypes.CF_PROMOONEOFFHEADERQ7Sub1:
      return clearance["promoOneoffHeaderQ7Sub1"];
    case sourceMetadataTypes.CF_PROMOONEOFFHEADERQ8:
      return clearance["promoOneoffHeaderQ8"];
    case sourceMetadataTypes.CF_PROMOONEOFFHEADERQ9:
      return clearance["promoOneoffHeaderQ9"];
    case sourceMetadataTypes.CF_CHANNELNAME:
      return clearance["channelName"];

    case sourceMetadataTypes.CF_PROGRAMMINGHEADERQ1:
      return clearance["programmingHeaderQ1"];
    case sourceMetadataTypes.CF_PROGRAMMINGHEADERQ2:
      return clearance["programmingHeaderQ2"];
    case sourceMetadataTypes.CF_PROGRAMMINGHEADERQ3:
      return clearance["programmingHeaderQ3"];
    case sourceMetadataTypes.CF_PROGRAMMINGHEADERQ4:
      return clearance["programmingHeaderQ4"];
    case sourceMetadataTypes.CF_PROGRAMMINGHEADERQ5:
      return clearance["programmingHeaderQ5"];
    case sourceMetadataTypes.CF_PROGRAMMINGHEADERQ6:
      return clearance["programmingHeaderQ6"];
    case sourceMetadataTypes.CF_PROGRAMMINGHEADERQ7:
      return clearance["programmingHeaderQ7"];
    // case sourceMetadataTypes.CF_PROGRAMMINGHEADERQ8:
      // return clearance["programmingHeaderQ8"];
    case sourceMetadataTypes.CF_PROGRAMMINGHEADERQ9:
      return clearance["programmingHeaderQ9"];
    case sourceMetadataTypes.CF_PROGRAMMINGHEADERQ10:
      return clearance["programmingHeaderQ10"];
    case sourceMetadataTypes.CF_PROGRAMMINGHEADERQ11:
      return clearance["programmingHeaderQ11"];
    case sourceMetadataTypes.CF_PROGRAMMINGHEADERQ12:
      return clearance["programmingHeaderQ12"];
    case sourceMetadataTypes.CF_PROGRAMMINGHEADERQ13:
      return clearance["programmingHeaderQ13"];
    case sourceMetadataTypes.CF_PROGRAMMINGHEADERQ14:
      return clearance["programmingHeaderQ14"];
    case sourceMetadataTypes.CF_PROGRAMMINGHEADERQ15:
      return clearance["programmingHeaderQ15"];

    case sourceMetadataTypes.CF_PROMOONEOFFHEADERQ10:
      return clearance["promoOneoffHeaderQ10"];
    case sourceMetadataTypes.CF_PROMOONEOFFHEADERQ12:
      return clearance["promoOneoffHeaderQ12"];
    case sourceMetadataTypes.CF_DELIVERYDATE:
      return clearance["deliveryDate"];
    case sourceMetadataTypes.CF_TXDATES:
      return clearance["txDates"];
    case sourceMetadataTypes.CF_USERGROUP:
      return clearance["userGroup"];
  }
};

const setCtag = (key, val) => (dispatch) => {
  let {
    clearanceRuntime: { ctags },
  } = store.getState();

  ctags[key] = val;
  return {
    type: actionTypes.CTAG,
    playload: ctags,
  };
};

const setCtagError = (key, val) => (dispatch) => {
  let {
    clearanceRuntime: { ctagErrors },
  } = store.getState();

  ctagErrors[key] = val;
  return {
    type: actionTypes.CTAG_ERROR,
    playload: ctagErrors,
  };
};

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const _getCTagAuto = async (tLookup, param, trackId, url) => {
  let track = tLookup[trackId] || {};
  // let textV = track[param.ctag] || "undefined";
  let textV = "undefined";
  let v = null;
  if (textV === "undefined") {
    let _ctag = trackCTags[param.ctag];

    const {
      clearanceRuntime: { ctags, copyLiveIds },
    } = store.getState();

    if (
      ctags[`${trackId}-${_ctag.group}`] &&
      ctags[`${trackId}-${_ctag.group}`].length > 0
    ) {
      let _tag = ctags[`${trackId}-${_ctag.group}`].find(
        (v) => v.id === _ctag.apiid
      );

      if (_tag && _tag.result == true) textV = track[param.ctag] = "Yes";
      else if (_tag && _tag.result == false) textV = track[param.ctag] = "No";
      else if (_tag && _tag.result == null) textV = track[param.ctag] = "N/A";
      else textV = track[param.ctag] = "N/A";
    } else if (ctags[`${trackId}-${_ctag.group}`]) {
      while (
        !ctags[`${trackId}-${_ctag.group}`] ||
        ctags[`${trackId}-${_ctag.group}`].length === 0
      ) {
        sleep(100).then(() => {
          return;
        });
      }

      let _tag = ctags[`${trackId}-${_ctag.group}`].find(
        (v) => v.id === _ctag.apiid
      );

      if (_tag && _tag.result == true) textV = track[param.ctag] = "Yes";
      else if (_tag && _tag.result == false) textV = track[param.ctag] = "No";
      else if (_tag && _tag.result == null) textV = track[param.ctag] = "N/A";
      else textV = track[param.ctag] = "N/A";
    } else {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          trackId:
            copyLiveIds && copyLiveIds[trackId]
              ? copyLiveIds[trackId]
              : trackId,
          ctagId: trackCTags[param.ctag].apiid,
        }),
      };

      let rep, json;

      try {
        let fetchRep = await fetch(url, requestOptions);
        if(fetchRep.ok){
          rep = fetchRep;
        }
        else{
          rep = null;
          store.dispatch(setCtagError(`${trackId}-${_ctag.group}`, "N/A"));
        }
      } catch (e) {
        rep = null;
        store.dispatch(setCtagError(`${trackId}-${_ctag.group}`, "N/A"));
      }

      json = rep ? await rep.json() : {};

      if (!Array.isArray(json?.cTags || null)) {
        json.cTags = [{ id: 0 }];
      }
      if (param.ctag == trackCTags.cTag2.id) {
        track[trackFields.prsWorkTunecode.id] = json.workTunecode;
        track[trackFields.prsSearchDateTime.id] = json.dateTime;
        track[trackFields.prsWorkPublishers.id] = json.workPublishers;
        track[trackFields.prsWorkWriters.id] = json.workWriters;
        track[trackFields.prsWorkTitle.id] = json.workTitle;
        if (!track[trackFields.prs.id])
          track[trackFields.prs.id] = json.workTunecode;
      }

      store.dispatch(setCtag(`${trackId}-${_ctag.group}`, json.cTags));
      let _tag = json.cTags.find((v) => v.id === _ctag.apiid);
      if (_tag && _tag.result == true) textV = track[param.ctag] = "Yes";
      else if (_tag && _tag.result == false) textV = track[param.ctag] = "No";
      else if (_tag && _tag.result == null) textV = track[param.ctag] = "N/A";
      else textV = track[param.ctag] = "N/A";
    }

    v = param.options.find((o) => {
      return o.id === textV;
    });
    return v ? v.id : "unknown";
  } else {
    v = param.options.find((o) => {
      return o.id === textV;
    });
    return v ? v.id : "unknown";
  }
};

export const __getAutoVal = async (tLookup, param, trackId) => {
  switch (param.parent) {
    case ParamObjectTypes.POT_TrackAuto:
      let tLookupDeepCopy = deepCopyFunction(tLookup);
      if(param.field == trackFields.mlCreated.id){
        if(tLookupDeepCopy[trackId][param.field] && typeof tLookupDeepCopy[trackId][param.field] == "boolean") {
          tLookupDeepCopy[trackId][param.field] = tLookupDeepCopy[trackId][param.field] == true?"Yes":"No";
        }
        else{
          tLookupDeepCopy[trackId][param.field] = "No";
        }
      }
      return _getTrackAuto(tLookupDeepCopy, param, trackId);

    case ParamObjectTypes.POT_TrackCTag:
      return await _getCTagAuto(
        tLookup,
        param,
        trackId,
        `${appConfig.CENTRAL_API}${appConfig.CTAG_ENDPOINT}`
      );
  }
};

export const __getAutoCFVal = async (clearance, param, trackId) => {
  switch (param.parent) {
    case ParamObjectTypes.POT_CFAuto:
      return _getCFAuto(clearance, param, trackId);
  }
};

export const __getParamLabel = (paramId, params) => {
  let param = params.find((v) => v.id === paramId);
  return param ? param.label || param.question || param.id : "na";
};

export const __getParamValue = (paramid, valueid, params) => {
  let _p = __getParam(paramid, params);

  // return (
  //   (_p.options || []).find((v) => v.id === valueid) || { label: "not found" }
  // );

  // hardcoded - START
  if( _p.parent === ParamObjectTypes.POT_CFAuto){
    switch (_p.type) {
      case sourceMetadataTypes.CF_TXDATES:
      case sourceMetadataTypes.CF_PROGRAMMINGHEADERQ7:
        let formattedDatesString = "";
        if(Array.isArray(valueid) && valueid.length > 0){
          formattedDatesString = valueid?.map(date => moment(date).format('DD/MM/YYYY')).join(' - ');
        }
        return ({ label: formattedDatesString });
      
      case sourceMetadataTypes.CF_PROGRAMMINGHEADERQ4:
        let hours = Math.floor(valueid / 3600);
        let minutes = Math.floor((valueid % 3600) / 60);
        let remainingSeconds = valueid % 60;
    
        let paddedHours = String(hours).padStart(2, '0');
        let paddedMinutes = String(minutes).padStart(2, '0');
        let paddedSeconds = String(remainingSeconds).padStart(2, '0');
      return ({ label:  `${paddedHours}:${paddedMinutes}:${paddedSeconds}`});

      case sourceMetadataTypes.CF_PROGRAMMINGHEADERQ5:
        return({ label:  valueid ? valueid : "not found"});
    
      default:
        return (
          (_p.options || []).find((v) => v.id === valueid) || { label: "not found" }
        );
    }

  } else if(_p.parent === ParamObjectTypes.POT_TrackQuestion){

    switch (_p.type) {
      case paramTypes.CPT_InputDuration:       
        let minutes = Math.floor((valueid % 3600) / 60);
        let remainingSeconds = valueid % 60;
    
        let paddedMinutes = String(minutes).padStart(2, '0');
        let paddedSeconds = String(remainingSeconds).padStart(2, '0');
      return ({ label:  `${paddedMinutes}:${paddedSeconds}`});
  
      case paramTypes.CPT_OptionsText: 
      case paramTypes.CPT_OptionsNumber: 
      return({ label:  valueid ? valueid : "not found"});
    
      default:
        return (
          (_p.options || []).find((v) => v.id === valueid) || { label: "not found" }
        );
    }
  }// hardcoded - END
  
  else{
    return (
      (_p.options || []).find((v) => v.id === valueid) || { label: "not found" }
    );
  }
};

export const __getQuestionAndAnswer = (cf, next, trackId) => {
  let letrun = {
    q: next.question,
    a: "",
    h: isHeaderQuestion(next),
  };
  let props = __getProps(cf, isHeaderQuestion(next) ? "" : trackId);
  let prop = __getProp(props, next.id) || { v: "" };
  letrun.a = (
    (next.options || []).find((v) => v.id === prop.v) || { label: "not found" }
  ).label;
  return letrun;
};

export const __getParam = (paramId, params) => {
  return (
    params.find((v) => v.id === paramId) || {
      label: "not found",
      options: [],
      parent: ParamObjectTypes.POT_HeaderQuestion,
      default: "",
    }
  );
};

export const __getGroupedQ = (next) => {
  let lreturn = {};
  next.map((v) => {
    let key = v.trackId || "0";
    if (!lreturn[key]) lreturn[key] = [];
    lreturn[key].push(v);
  });

  return lreturn;
};

export const __clone = (data) => {
  return JSON.parse(JSON.stringify(data));
};

export const __sortParams = (trigger, cf) => {
  trigger = trigger.sort((a, b) => {
    let p1 = __getParam(a.param, cf._params);
    let p2 = __getParam(b.param, cf._params);

    if (
      p1.parent === ParamObjectTypes.POT_TrackAuto ||
      p1.parent === ParamObjectTypes.POT_TrackCTag ||
      p1.parent === ParamObjectTypes.POT_Script
    ) {
      if (
        p2.parent === ParamObjectTypes.POT_TrackAuto ||
        p2.parent === ParamObjectTypes.POT_TrackCTag ||
        p2.parent === ParamObjectTypes.POT_Script
      )
        return 0;
      else return -1;
    } else if (p1.parent === ParamObjectTypes.POT_HeaderQuestion) {
      if (
        p2.parent === ParamObjectTypes.POT_TrackAuto ||
        p2.parent === ParamObjectTypes.POT_TrackCTag ||
        p2.parent === ParamObjectTypes.POT_Script
      )
        return 1;
      else if (p2.parent === ParamObjectTypes.POT_HeaderQuestion) return 0;
      else return -1;
    } else {
      if (
        p2.parent === ParamObjectTypes.POT_TrackAuto ||
        p2.parent === ParamObjectTypes.POT_TrackCTag ||
        p2.parent === ParamObjectTypes.POT_Script
      )
        return 1;
      else if (p2.parent === ParamObjectTypes.POT_HeaderQuestion) return 1;
      else return 0;
    }
  });

  //console.log([...trigger]);
};

export const __removeTrack = (cf, id) => {
  if (cf.commits && cf.commits[id]) delete cf.commits[id];
  let newprops = cf.props.filter((v) => v.trackId !== id);
  cf.props = newprops;
};

export const isHeaderQuestion = (p) => {
  return p.parent === ParamObjectTypes.POT_HeaderQuestion ||
    p.parent === ParamObjectTypes.POT_CFAuto
    ? true
    : false;
};
