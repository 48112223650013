import React, { Component } from "react";
import { Translation } from "react-i18next";
import { _getModuleDef, _newModuleInstance } from "_module/utils/ModuleUtils";
import {
  withStyles,
  Select,
  MenuItem,
} from "@material-ui/core";
import { BsTrash } from "react-icons/bs";
import { styles } from "./ModuleEdit.css";
 

class ModuleEditOptionsSingleSelect extends Component {
  constructor(props) {
    super();
   
  }

  onValueChange = (event) =>{
    var {handleChange} = this.props;
    handleChange(event.target.value);

  }

  clear(e){
    var {handleChange} = this.props;
    handleChange('');
  }

  render() {
    
    var {classes, selected, options, isRemovable } = this.props;
  
    return (
      <Translation>
        {(t, { i18n }) => (
          <>
         <div className={classes.resultsWeightContainer}>

     
          {<Select
            name='singleselect'
            fullWidth
            value={selected}
            onChange={this.onValueChange}
            variant="outlined"
            inputProps={{
              id: 'singleselect',
              "aria-labelledby": 'singleselect',
            }}
          >
            {Object.keys(options).map((id, index) => (
              <MenuItem key={options[id].id} value={options[id].id}>
                {options[id].name}
              </MenuItem>
            ))}
          </Select> }

            { isRemovable && (
              <span onClick = {(e)=>this.clear(e)}><BsTrash/></span>
            ) }
          </div>
          </>
        )}
      </Translation>
    );
  }
}

export default withStyles(styles)(ModuleEditOptionsSingleSelect);
