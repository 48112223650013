import React, { Component } from "react";

import { Translation } from "react-i18next";
import { T_Branch, T_Major } from "_module/definition/ModulePropTypes";
import { _getModuleDef} from "_module/utils/ModuleUtils";
import { _newModuleInstance } from "../../utils/newModuleInstance";
import {
  withStyles,
  Checkbox,
  Divider,
  Switch,
} from "@material-ui/core";
import { styles } from "./ModuleEdit.css";
import Modules from "_module/definition/Modules";
import {
  T_LeafOptionsMulti,
  T_LeafOptionsMultiOrdered,
  T_LeafOptionsSingle,
} from "../../definition/ModulePropTypes";
import ModuleEditOptions from "./ModuleEditOptions";

class ModuleEdit extends Component {
  constructor(props) {
    super();
  }

  handleChildChange = (id, value) => {
    let m = this.props.module;
    if (this.props.handleChildChange)
      this.props.handleChildChange(this.props.moduleDef.id, m);
  };

  handleChange = (event) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    let m = this.props.module;
    m.v = value;
    this.props.handleChildChange(this.props.moduleDef.id, m);
  };

  handleBranchOnOff = (event) => {
    const value = event.target.checked;
    let m = null;
    if (value)
      m = _newModuleInstance(
        Modules.find((v) => v.id === this.props.moduleDef.id)
      );
    this.props.handleChildChange(this.props.moduleDef.id, m);
  };

  fillmodule = (m, id )=> { 
    m[id] = _newModuleInstance(_getModuleDef(id));
    return m[id]; 
  }

  render() {
    var { module, moduleDef, classes } = this.props;

    return (
      <Translation>
        {(t, { i18n }) =>
          !moduleDef ? (
            <div className={classes.roleAddPermission}>
              {t("modules.ModuleNotFound")}: {t(`modules.${module.id}`)}
            </div>
          ) : moduleDef.type === T_Major ? (
            <div>
              {/* <div className={classes.moduleControllar}>
             <span> {t(`modules.${moduleDef.id}`)}</span>
              <button className={classes.toolsButton} onClick={(e) => this.props.onRemove(moduleDef.id)}>
                 <BiTrash/>
              </button>              
              </div> */}

              {moduleDef.modules.map((v) => (
                <div key={v.id}>
                  <ModuleEdit
                    classes={classes}
                    key={v.id}
                    module={module[v.id] || this.fillmodule(module, v.id )}
                    moduleDef={_getModuleDef(v.id)}
                    handleChildChange={(id, val) =>
                      this.handleChildChange(id, val)
                    }
                  />
                  <Divider className={classes.roledividerMargin} />
                </div>
              ))}
            </div>
          ) : moduleDef.type === T_Branch ? (
            <div>
              {/* <Switch
                id={moduleDef.id}
                checked={module ? true : false}
                onChange={this.handleBranchOnOff}
              /> */}
              <Checkbox
                id={moduleDef.id}
                checked={module ? true : false}
                onChange={this.handleBranchOnOff}
                inputProps={{
                  'aria-label': "Branch on/off"
                }}
              />{" "}
              {t(`modules.${moduleDef.id}`)}
              {moduleDef.modules.map((v) => (
                <ModuleEdit
                  classes={classes}
                  key={v.id}
                  module={module && module[v.id] ? module[v.id] : null}
                  moduleDef={_getModuleDef(v.id)}
                  handleChildChange={(id, val) =>
                    this.handleChildChange(id, val)
                  }
                />
              ))}
            </div>
          ) : moduleDef.type === T_LeafOptionsSingle ? (
            <div className={classes.roleAddPermission}>
              {t(`modules.${moduleDef.id}`)}
              <ModuleEditOptions
                classes={classes}
                module={module}
                moduleDef={moduleDef}
                handleChildChange={(id, val) => this.handleChildChange(id, val)}
              />
            </div>
          ) : moduleDef.type === T_LeafOptionsMultiOrdered ? (
            <div className={classes.roleAddPermission}>
              {t(`modules.${moduleDef.id}`)}
              <ModuleEditOptions
                ordered
                multiple
                classes={classes}
                module={module}
                moduleDef={moduleDef}
                handleChildChange={(id, val) => this.handleChildChange(id, val)}
              />
            </div>
          ) : moduleDef.type === T_LeafOptionsMulti ? (
            <div className={classes.roleAddPermission}>
              {t(`modules.${moduleDef.id}`)}
              <ModuleEditOptions
                multiple
                classes={classes}
                module={module}
                moduleDef={moduleDef}
                handleChildChange={(id, val) => this.handleChildChange(id, val)}
              />
            </div>
          ) : (
            <div className={classes.moduleEditContainer}>
              <Switch
                className={classes.modulesSwitch}
                id={moduleDef.id}
                checked={module && module.v ? module.v : false}
                disabled={module == null ? true : false}
                onChange={this.handleChange}
                inputProps={{
                  'aria-label': "Switch on/off"
                }}
              />
              {/* <Checkbox
                id={moduleDef.id}
                checked={module && module.v ? module.v : false}
                disabled={module == null ? true : false}
                onChange={this.handleChange}
              /> */}
              <span>{t(`modules.${moduleDef.id}`)}</span>
            </div>
          )
        }
      </Translation>
    );
  }
}

export default withStyles(styles)(ModuleEdit);
